<template>
  <ui-dropdown
    v-if="!breakpointSmallerLg"
    :model-value="modelValue"
    :options="options"
    :placeholder="placeholder"
    :option-value="optionValue"
    :option-label="optionLabel"
    :class="classes"
    @update:model-value="$emit('update:model-value', $event)"
  >
    <template #option="{ option }">
      <slot
        name="option"
        :option="option"
      />
    </template>
  </ui-dropdown>

  <div
    v-else
    :class="['ui-dropdown tw-justify-between', classes]"
    @click="vfm.open(modalId)"
  >
    <span class="tw-text-base tw-font-normal tw-text-dark-gray">{{ selectedValue }}</span>

    <ui-icon-base
      :icon="icons['chevron-down']"
      :width="20"
      :height="20"
      :view-box-size="[20, 20]"
      :stroke-width="1.6"
      stroke-linejoin="round"
      stroke-linecap="round"
      stroke="#454545"
      fill="none"
    />
  </div>

  <sp-dropdown-modal
    :modal-id="modalId"
    :label="label"
    :option-label="optionLabel"
    :option-value="optionValue"
    :options="options"
  >
    <template #modal-option="{ option, close }">
      <slot
        name="modal-option"
        :option="option"
        :close="close"
        :model-value="modelValue"
      />
    </template>
  </sp-dropdown-modal>
</template>

<script>
// Components
import SpDropdownModal from '@/modals/sp-dropdown-modal/sp-dropdown-modal.vue'

// Utils
import icons from '@/utils/icons'

// Plugins, libraries
import { useBreakpoints, breakpointsTailwind } from '@vueuse/core'
import { toRefs, computed } from 'vue'
import { useVfm } from 'vue-final-modal'

export default {
  name: 'sp-dropdown',
  components: {
    SpDropdownModal
  },
  props: {
    classes: {
      type: [Array, String],
      default: ''
    },
    modalId: {
      type: String,
      required: true
    },
    modelValue: {
      type: [Object, String, Number, null],
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    optionLabel: {
      type: String,
      default: 'name'
    },
    optionValue: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      required: true
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const vfm = useVfm()

    const breakpoints = useBreakpoints({
      ...breakpointsTailwind,
      '2xl': '1440px',
      '3xl': '1696px',
      '4xl': '1920px'
    })
    const breakpointSmallerLg = breakpoints.smaller('lg')

    const { modelValue, optionLabel, placeholder } = toRefs(props)
    const selectedValue = computed(() => {
      if (!modelValue.value) {
        return placeholder.value
      }

      return optionLabel.value ? modelValue.value[optionLabel.value] : modelValue.value
    })

    return {
      breakpointSmallerLg,
      icons,
      selectedValue,
      vfm
    }
  }
}
</script>
