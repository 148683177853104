<template>
  <div
    class="tw-flex tw-flex-col tw-items-end tw-w-full tw-gap-2 5xl:tw-gap-[10px]"
    data-cy="articles-date"
  >
    <div class="tw-flex tw-items-center tw-justify-between tw-w-full">
      <div class="tw-flex tw-items-start">
        <ui-button
          class="ui-button_md ui-button_text"
          value="Delete"
          data-cy="delete-articles"
          @click="onDeleteArticle(selectedArticles, onResetSelect)"
        />

        <ui-button
          class="ui-button_md"
          value="Cancel"
          data-cy="cancel-select"
          @click="onResetSelect"
        />
      </div>

      <span class="tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray">
        Upload date: {{ getFormattingDate(date) }}
      </span>
    </div>

    <div class="tw-flex tw-flex-col tw-items-start tw-gap-6 5xl:tw-gap-[30px] tw-w-full tw-overflow-x-auto tw-relative">
      <ui-table
        :headers="headers"
        class="sp-table tw-min-w-[1550px]"
      >
        <template #header[remove-action]>
          <ui-checkbox
            :value="selectAll"
            :classes="['ui-checkbox_base tw-cursor-pointer']"
            data-cy="select-all-articles"
            @click="onSelectAll(items)"
          />
        </template>

        <template #default>
          <ui-row
            :headers="headers"
            :items="items"
            :classes="['tw-border-t tw-border-middle-gray']"
          >
            <template #item[remove-action]="{ item }">
              <ui-checkbox
                :value="isSelected(item.UF_OEM, item.UF_DATA)"
                :classes="['ui-checkbox_base tw-cursor-pointer']"
                data-cy="select-article"
                @click="onSelectArticle(item)"
              />
            </template>

            <template #item[UF_DATA]="{ item }">
              <span>{{ getFormattingDate(item.UF_DATA) }}</span>
            </template>

            <template #item[UF_SCHEMA]="{ item }">
              <a
                :href="item.UF_SCHEMA"
                :class="[
                  'tw-text-sm 5xl:tw-text-[17px] tw-font-semibold tw-cursor-pointer',
                  item.UF_SCHEMA ? 'tw-text-orange' : ' tw-text-middle-gray tw-pointer-events-none'
                ]"
                target="_blank"
              >
                Schema
              </a>
            </template>

            <template #item[UF_ANALOGS]="{ item }">
              <tippy
                v-if="item.UF_ANALOGS.length"
                theme="history"
                trigger="click"
                hide-on-click="toggle"
                content-class="sp-tooltip"
                :on-click-outside="instance => instance.hide()"
              >
                <template #default>
                  <span class="tw-text-sm 5xl:tw-text-[17px] tw-font-semibold tw-cursor-pointer">Where is used?</span>
                </template>

                <template #content>
                  <div class="tw-flex tw-flex-col tw-items-start tw-gap-2 5xl:tw-gap-[10px]">
                    <span
                      v-for="(analog, key) in item.UF_ANALOGS"
                      :key="key"
                      class="tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-dark-gray"
                    >
                      {{ analog }}
                    </span>
                  </div>
                </template>
              </tippy>

              <span
                v-else
                class="tw-text-sm 5xl:tw-text-[17px] tw-font-semibold tw-text-middle-gray tw-pointer-events-none"
              >
                Where is used?
              </span>
            </template>

            <template #empty>
              <div class="tw-w-full tw-border-t tw-border-middle-gray tw-text-center tw-py-3 5xl:tw-py-[15px]">
                <span class="tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray">No data</span>
              </div>
            </template>
          </ui-row>
        </template>
      </ui-table>
    </div>

    <ui-button
      value="Exporting articles in xlsx"
      class="ui-button_md ui-button_primary tw-w-full lg:tw-w-auto tw-mr-auto tw-mt-2 5xl:tw-mt-[10px]"
      @click="onExportHandler(items)"
    />
  </div>
</template>

<script>
import { Tippy } from 'vue-tippy'
import 'tippy.js/dist/tippy.css'

import { getFormattingDate } from '@/utils/getFormattingDate'
import { selectArticlesMixin } from '@/mixins/select-articles.mixin'
import GenerateXlsx from '@/classes/GenerateXlsx'

import { useStore } from 'vuex'
import { ref } from 'vue'

export default {
  name: 'tables',
  components: {
    Tippy
  },
  props: {
    date: {
      type: [Number, String],
      default: null
    },
    headers: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    }
  },
  setup () {
    const store = useStore()
    const selectAll = ref(false)

    async function getArticles () {
      const { articles = [] } = await store.dispatch('ArticlesModule/getArticles')
      store.commit('ArticlesModule/setArticles', { articles, targetArray: 'articles' })
    }

    async function onDeleteArticle (articles, cb) {
      await store.dispatch('ArticlesModule/removeArticle', {
        articles: articles.map(item => item.UF_OEM),
        isPendingPage: false
      })
      await getArticles()
      await store.dispatch('getCountInfo')

      cb()
    }

    async function onExportHandler (items) {
      const headers = [
        { key: 'UF_OEM', header: 'OEM Number' },
        { key: 'UF_SUPPL_ID', header: 'Suppl ID' },
        { key: 'UF_PRICE', header: 'Price' },
        { key: 'UF_COUNT', header: 'Count' },
        { key: 'UF_DATA', header: 'Date' },
        { key: 'UF_DESCRIPTION', header: 'Description' },
        { key: 'UF_AVAIBLE', header: 'Available' },
        { key: 'UF_STOCK_INFO', header: 'Stock info' },
        { key: 'UF_PRODUCTION_TIME', header: 'Production time' }
      ]
      const mappedItems = items.map(item => {
        return {
          ...item,
          UF_DATA: getFormattingDate(item.UF_DATA)
        }
      })
      const xlsx = new GenerateXlsx(headers, mappedItems)
      const filename = xlsx.createFilename('articles')
      const xlsxData = xlsx.createSimpleSheet()

      await xlsx.generateXlsx(filename, 'Articles', xlsxData)
    }

    return {
      getFormattingDate,
      onDeleteArticle,
      onExportHandler,
      selectAll,
      ...selectArticlesMixin()
    }
  }
}
</script>
