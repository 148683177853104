<template>
  <ui-sidebar class="sp-menu">
    <template #default>
      <div class="tw-flex tw-flex-col tw-w-full tw-h-full tw-gap-[42px]">
        <div class="tw-flex tw-items-center tw-h-[44px]">
          <router-link
            to="/"
            class="tw-relative"
          >
            <ui-icon-base
              :icon="icons['logo']"
              :width="177"
              :height="49"
              :view-box-size="[177, 49]"
              class="5xl:tw-w-[221px] 5xl:tw-h-[61px]"
            />
          </router-link>
        </div>

        <div class="tw-flex tw-flex-col tw-w-full tw-gap-0.5">
          <div
            v-for="(item, index) in menu"
            :key="index"
            class="tw-flex tw-flex-col tw-w-full tw-gap-0.5"
          >
            <sp-navigation
              v-for="(element, index) in item.items"
              :key="index"
              :url="element.url"
              :text="element.text"
            >
              <template #icon>
                <template v-if="element.icon">
                  <ui-icon-base
                    :icon="icons[element.icon.name]"
                    :width="element.icon.width"
                    :height="element.icon.height"
                    :view-box-size="element.icon.viewBoxSize"
                    :fill="element.icon.fill"
                    class="5xl:tw-w-[18px] 5xl:tw-h-[18px]"
                  />
                </template>
              </template>
            </sp-navigation>
          </div>
        </div>

        <sp-suppliers />

        <div class="min-[1025px]:tw-flex tw-flex-col tw-hidden md:tw-flex tw-mt-auto tw-gap-[34px]">
          <sp-user />
        </div>
      </div>
    </template>
  </ui-sidebar>
</template>

<script>
import SpNavigation from '@/modules/sp-side-menu/components/sp-navigation/sp-navigation.vue'
import SpSuppliers from '@/modules/sp-suppliers/sp-suppliers.vue'
import SpUser from '@/modules/sp-side-menu/components/sp-user.vue'

import icons from '@/utils/icons'
import { menu } from '@/mixins/menu.mixin'

export default {
  name: 'sp-side-menu',
  components: {
    SpNavigation,
    SpSuppliers,
    SpUser
  },
  setup () {
    return {
      icons,
      ...menu()
    }
  }
}
</script>

<style lang="scss">
@import './sp-side-menu';
</style>
