<template>
  <vue-final-modal
    :modal-id="modalId"
    overlay-class="sp-modal-overlay"
    class="sp-modal-container"
    content-class="sp-modal-content sp-modal-dropdown"
    content-transition="vfm-slide-down"
    :esc-to-close="true"
  >
    <template #default="{ close }">
      <div class="sp-modal-wrapper">
        <div class="tw-flex tw-flex-col tw-items-start tw-w-full tw-gap-5">
          <h2 class="tw-text-lg tw-font-medium tw-text-black">{{ label }}</h2>

          <div class="tw-flex tw-flex-col tw-items-start tw-w-full tw-gap-4">
            <div
              v-for="(item, index) in options"
              :key="index"
              class="tw-flex tw-items-center tw-w-full"
            >
              <slot
                name="modal-option"
                :option="item"
                :close="close"
              >
                <div class="tw-flex tw-items-center tw-w-full tw-py-3 tw-px-4 tw-rounded-[5px]">
                  <span class="tw-text-sm tw-font-medium tw-text-dark-gray">
                    {{ item[optionLabel] }}
                  </span>
                </div>
              </slot>
            </div>
          </div>
        </div>

        <div
          class="sp-modal-close"
          @click="close"
        >
          <ui-icon-base
            :icon="icons['close']"
            :width="15"
            :height="15"
            :view-box-size="[15, 15]"
            :stroke-width="2"
            stroke="#6C6D70"
          />
        </div>
      </div>
    </template>
  </vue-final-modal>
</template>

<script>
import icons from '@/utils/icons'
import { VueFinalModal } from 'vue-final-modal'

export default {
  name: 'sp-dropdown-modal',
  components: {
    VueFinalModal
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    modalId: {
      type: String,
      required: true
    },
    modelValue: {
      type: [Object, String, Number, null],
      default: null
    },
    optionLabel: {
      type: String,
      default: 'name'
    },
    optionValue: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  setup () {
    return {
      icons
    }
  }
}
</script>
