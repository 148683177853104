<template>
  <vue-final-modal
    overlay-class="sp-modal-overlay"
    class="sp-modal-container"
    content-class="sp-modal-content sp-modal-create-nomenclature"
    content-transition="vfm-slide-down"
    :esc-to-close="true"
  >
    <div class="sp-modal-wrapper">
      <div class="tw-flex tw-flex-col tw-items-start tw-w-full tw-gap-8">
        <h2 class="tw-text-lg tw-font-semibold tw-text-black">Add an nomenclature manually</h2>

        <form
          class="tw-flex tw-flex-col tw-items-start tw-w-full tw-gap-6"
          @submit.prevent="onSubmitHandler(close)"
        >
          <div class="tw-flex tw-flex-col tw-items-start tw-w-full tw-gap-4">
            <div class="tw-grid tw-grid-cols-1 min-[560px]:tw-grid-cols-2 tw-gap-4 tw-w-full">
              <ui-input
                v-for="(input, index) in groupFields"
                v-model="input.value"
                :key="index"
                :name="input.name"
                :type="input.type"
                :placeholder="input.placeholder"
                :class="input.classes"
              >
                <template #label>
                  <span class="tw-text-sm tw-font-medium tw-text-dark-gray">{{ input.label }}</span>
                </template>
              </ui-input>
            </div>

            <ui-input
              v-for="(input, index) in simpleFields"
              v-model="input.value"
              :key="index"
              :name="input.name"
              :type="input.type"
              :placeholder="input.placeholder"
              :class="input.classes"
            >
              <template #label>
                <span class="tw-text-sm tw-font-medium tw-text-dark-gray">{{ input.label }}</span>
              </template>
            </ui-input>
          </div>

          <div class="tw-grid tw-grid-cols-1 min-[560px]:tw-grid-cols-2 tw-w-full tw-gap-4">
            <ui-upload
              class="tw-w-full"
              @upload:file="onUploadFile($event)"
            >
              <template #default>
                <div class="ui-button ui-button_md ui-button_outlined tw-w-full tw-cursor-pointer">
                  <ui-icon-base
                    :icon="icons['attach']"
                    :width="15"
                    :height="16"
                    :view-box-size="[15, 16]"
                  />

                  Add tech documentation
                </div>
              </template>
            </ui-upload>

            <ui-button
              value="Save"
              class="ui-button_md ui-button_primary tw-w-full"
              data-cy="create-article"
            />
          </div>
        </form>
      </div>

      <div
        class="sp-modal-close"
        @click="$emit('update:modelValue', false)"
      >
        <ui-icon-base
          :icon="icons['close-md']"
          :width="20"
          :height="20"
          :view-box-size="[20, 20]"
          fill=""
          :stroke-width="1.6"
          stroke="#6C6D70"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
import icons from '@/utils/icons'
import { inputTypes } from '@/constants/input-types'
import { storages } from '@/constants/storages'

import { ref } from 'vue'
import { useStore } from 'vuex'
import { useToast } from 'primevue/usetoast'
import { VueFinalModal } from 'vue-final-modal'

export default {
  name: 'sp-create-nomenclature-modal',
  components: {
    VueFinalModal
  },
  setup (props, { emit }) {
    const toast = useToast()
    const store = useStore()

    const uploadedFile = ref(null)
    const groupFields = ref([
      {
        name: 'UF_OEM',
        type: inputTypes.TEXT,
        value: '',
        label: 'ОЕМ number*',
        placeholder: 'Number',
        classes: 'tw-items-start tw-gap-[6px]'
      },
      {
        name: 'UF_SUPPL_ID',
        type: inputTypes.TEXT,
        value: '',
        label: 'Supply ID',
        placeholder: 'ID',
        classes: 'tw-items-start tw-gap-[6px]'
      },
      {
        name: 'UF_PRICE',
        type: inputTypes.NUMBER,
        value: '',
        label: 'Price',
        placeholder: 'Price',
        classes: 'tw-items-start tw-gap-[6px]'
      },
      {
        name: 'UF_AVAIBLE',
        type: inputTypes.TEXT,
        value: '',
        label: 'Availability',
        placeholder: 'Availability',
        classes: 'tw-items-start tw-gap-[6px]'
      }
    ])
    const simpleFields = ref([
      {
        name: 'UF_PRODUCTION_TIME',
        type: inputTypes.TEXT,
        value: '',
        label: 'Production time',
        placeholder: 'Time',
        classes: ''
      },
      {
        name: 'UF_DESCRIPTION',
        type: inputTypes.TEXT,
        value: '',
        label: 'Description',
        placeholder: 'Description',
        classes: ''
      },
      {
        name: 'UF_STOCK_INFO',
        type: inputTypes.TEXT,
        value: '',
        label: 'Stock info',
        placeholder: 'Info',
        classes: 'tw-items-start tw-gap-[6px]'
      }
    ])

    const onSubmitHandler = () => {
      const fields = [...groupFields.value, ...simpleFields.value]
      const article = {}

      fields.forEach(item => {
        article[item.name] = item.value
      })

      article.UF_LINK_FILE = uploadedFile.value
      article.UF_ANALOGS = []
      article.UF_DATA = Date.now()

      store.commit('ArticlesModule/appendUploadedArticles', article)

      localStorage.setItem(storages.uploadPageArticles, JSON.stringify(store.state.ArticlesModule.articlesUploaded))
      localStorage.setItem(storages.uploadPageArticlesCount, JSON.stringify(store.state.ArticlesModule.countUploadedArticles))

      emit('update:model-value', false)
      toast.add({
        detail: 'Articles have been added',
        life: 3000
      })
    }

    async function onUploadFile (file) {
      if (!file) {
        return alert('Загрузите файл')
      }

      const formData = new FormData()
      formData.append('file', file)

      uploadedFile.value = await store.dispatch('uploadFile', formData).then(res => res?.result)
    }

    return {
      groupFields,
      icons,
      onSubmitHandler,
      onUploadFile,
      simpleFields
    }
  }
}
</script>

<style lang="scss">
@import './sp-create-nomenclature-modal';
</style>
