import Api from '@/api'
import { storages } from '@/constants/storages'

export default {
  namespaced: true,
  state: {
    token: null,
    user: {}
  },
  mutations: {
    logout (state) {
      state.token = null
      state.user = {}

      localStorage.setItem(storages.userData, null)
      localStorage.setItem(storages.userToken, null)
    },
    setRole (state, role) {
      state.user.role = role
    },
    setToken (state, token) {
      state.token = token
    },
    setUser (state, user) {
      state.user = user
    },
    setUserId (state, id) {
      state.user.id = id
    },
    updateProfileData (state, { key, value }) {
      state.user[key] = value
    }
  },
  actions: {
    async getProfileData ({ state }) {
      const {
        id,
        role = null,
        firstName = '',
        lastName = '',
        email = '',
        phone = '',
        company = '',
        country = ''
      } = await Api.get('/api/supply/user', {})

      state.user = {
        id,
        role,
        firstName,
        lastName,
        email,
        phone,
        company,
        country
      }

      localStorage.setItem(storages.userData, JSON.stringify(state.user))
    },
    async login ({ commit }, { login, password }) {
      return await Api.post('/api/new/auth', {
        data: {
          login,
          password
        }
      })
    },
    async saveUserData ({ state }) {
      const { id, firstName, lastName, phone, company, country } = state.user

      await Api.put('/api/supply/user/edit', {
        data: {
          id,
          firstName,
          lastName,
          phone,
          company,
          country
        }
      })
    }
  }
}
