<template>
  <div
    v-if="isManager"
    class="tw-flex tw-flex-col tw-items-start tw-w-full tw-gap-8"
  >
    <div
      v-for="(item, index) in searchResult"
      :key="index"
      class="tw-flex tw-flex-col tw-items-end tw-w-full tw-gap-2 5xl:tw-gap-[10px]"
      data-cy="articles-date"
    >
      <div class="tw-flex tw-items-center tw-justify-between tw-w-full">
        <span class="tw-text-sm 5xl:tw-text-[17px] tw-font-medium tw-text-dark-gray">
          Supplier: {{ item.supply }}
        </span>
      </div>

      <div class="tw-flex tw-flex-col tw-items-start tw-gap-6 5xl:tw-gap-[30px] tw-w-full tw-overflow-x-auto tw-relative">
        <ui-table
          :headers="headers"
          class="sp-table tw-min-w-[1740px]"
        >
          <template #default>
            <ui-row
              :headers="headers"
              :items="item.articles"
              :classes="['tw-border-t tw-border-middle-gray']"
            >
              <template #item[UF_DATA]="{ item }">
                <span>{{ getFormattingDate(item.UF_DATA) }}</span>
              </template>

              <template #item[UF_SCHEMA]="{ item }">
                <a
                  :href="item.UF_SCHEMA"
                  :class="[
                    'tw-text-sm 5xl:tw-text-[17px] tw-font-semibold tw-cursor-pointer',
                    item.UF_SCHEMA ? 'tw-text-orange' : ' tw-text-middle-gray tw-pointer-events-none'
                  ]"
                  target="_blank"
                >
                  Schema
                </a>
              </template>

              <template #item[UF_ANALOGS]="{ item }">
                <tippy
                  v-if="item.UF_ANALOGS.length"
                  theme="history"
                  trigger="click"
                  hide-on-click="toggle"
                  content-class="sp-tooltip"
                  :on-click-outside="instance => instance.hide()"
                >
                  <template #default>
                    <span class="tw-text-sm 5xl:tw-text-[17px] tw-font-semibold tw-cursor-pointer">Where is used?</span>
                  </template>

                  <template #content>
                    <div class="tw-flex tw-flex-col tw-items-start tw-gap-2 5xl:tw-gap-[10px]">
                      <span
                        v-for="(analog, key) in item.UF_ANALOGS"
                        :key="key"
                        class="tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-dark-gray"
                      >
                        {{ analog }}
                      </span>
                    </div>
                  </template>
                </tippy>

                <span
                  v-else
                  class="tw-text-sm 5xl:tw-text-[17px] tw-font-semibold tw-text-middle-gray tw-pointer-events-none"
                >
                  Where is used?
                </span>
              </template>

              <template #item[HISTORY_PRICES]="{ item }">
                <tippy
                  v-if="item.HISTORY_PRICES.length"
                  theme="history"
                  trigger="click"
                  hide-on-click="toggle"
                  content-class="sp-tooltip"
                  :on-click-outside="instance => instance.hide()"
                >
                  <template #default>
                    <span class="tw-text-sm 5xl:tw-text-[17px] tw-font-semibold tw-cursor-pointer">History prices</span>
                  </template>

                  <template #content>
                    <div class="tw-flex tw-flex-col tw-items-start tw-gap-2 5xl:tw-gap-[10px]">
                      <div class="tw-grid tw-grid-cols-2 tw-w-full tw-gap-3">
                        <span class="tw-text-sm tw-font-medium tw-text-dark-gray">Date</span>
                        <span class="tw-text-sm tw-font-medium tw-text-dark-gray">Price</span>
                      </div>

                      <div
                        v-for="(item, key) in item.HISTORY_PRICES"
                        :key="key"
                        class="tw-grid tw-grid-cols-2 tw-w-full tw-gap-3"
                      >
                        <span class="tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-dark-gray">
                          {{ getFormattingDate(item.UF_DATA) }}
                        </span>

                        <span class="tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-dark-gray">
                          {{ item.UF_PRICE }}
                        </span>
                      </div>
                    </div>
                  </template>
                </tippy>

                <span
                  v-else
                  class="tw-text-sm 5xl:tw-text-[17px] tw-font-semibold tw-text-middle-gray tw-pointer-events-none"
                >
                  History prices
                </span>
              </template>

              <template #empty>
                <div class="tw-w-full tw-border-t tw-border-middle-gray tw-text-center tw-py-3 5xl:tw-py-[15px]">
                  <span class="tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray">No data</span>
                </div>
              </template>
            </ui-row>
          </template>
        </ui-table>
      </div>
    </div>
  </div>

  <div
    v-else
    class="tw-flex tw-flex-col tw-items-start tw-gap-6 5xl:tw-gap-[30px] tw-w-full tw-overflow-x-auto tw-relative"
  >
    <ui-table
      :headers="headers"
      class="sp-table tw-min-w-[1550px]"
    >
      <template #default>
        <ui-row
          :headers="headers"
          :items="searchResult"
          :classes="['tw-border-t tw-border-middle-gray']"
        >
          <template #item[UF_DATA]="{ item }">
            <span>{{ getFormattingDate(item.UF_DATA) }}</span>
          </template>

          <template #item[UF_SCHEMA]="{ item }">
            <a
              :href="item.UF_SCHEMA"
              :class="[
                    'tw-text-sm 5xl:tw-text-[17px] tw-font-semibold tw-cursor-pointer',
                    item.UF_SCHEMA ? 'tw-text-orange' : ' tw-text-middle-gray tw-pointer-events-none'
                  ]"
              target="_blank"
            >
              Schema
            </a>
          </template>

          <template #item[UF_ANALOGS]="{ item }">
            <tippy
              v-if="item.UF_ANALOGS.length"
              theme="history"
              trigger="click"
              hide-on-click="toggle"
              content-class="sp-tooltip"
              :on-click-outside="instance => instance.hide()"
            >
              <template #default>
                <span class="tw-text-sm 5xl:tw-text-[17px] tw-font-semibold tw-cursor-pointer">Where is used?</span>
              </template>

              <template #content>
                <div class="tw-flex tw-flex-col tw-items-start tw-gap-2 5xl:tw-gap-[10px]">
                      <span
                        v-for="(analog, key) in item.UF_ANALOGS"
                        :key="key"
                        class="tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-dark-gray"
                      >
                        {{ analog }}
                      </span>
                </div>
              </template>
            </tippy>

            <span
              v-else
              class="tw-text-sm 5xl:tw-text-[17px] tw-font-semibold tw-text-middle-gray tw-pointer-events-none"
            >
              Where is used?
            </span>
          </template>

          <template #item[HISTORY_PRICES]="{ item }">
            <tippy
              v-if="item.HISTORY_PRICES.length"
              theme="history"
              trigger="click"
              hide-on-click="toggle"
              content-class="sp-tooltip"
              :on-click-outside="instance => instance.hide()"
            >
              <template #default>
                <span class="tw-text-sm 5xl:tw-text-[17px] tw-font-semibold tw-cursor-pointer">History prices</span>
              </template>

              <template #content>
                <div class="tw-flex tw-flex-col tw-items-start tw-gap-2 5xl:tw-gap-[10px]">
                  <div class="tw-grid tw-grid-cols-2 tw-w-full tw-gap-3">
                    <span class="tw-text-sm tw-font-medium tw-text-dark-gray">Date</span>
                    <span class="tw-text-sm tw-font-medium tw-text-dark-gray">Price</span>
                  </div>

                  <div
                    v-for="(item, key) in item.HISTORY_PRICES"
                    :key="key"
                    class="tw-grid tw-grid-cols-2 tw-w-full tw-gap-3"
                  >
                    <span class="tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-dark-gray">
                      {{ getFormattingDate(item.UF_DATA) }}
                    </span>

                    <span class="tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-dark-gray">
                      {{ item.UF_PRICE }}
                    </span>
                  </div>
                </div>
              </template>
            </tippy>

            <span
              v-else
              class="tw-text-sm 5xl:tw-text-[17px] tw-font-semibold tw-text-middle-gray tw-pointer-events-none"
            >
              History prices
            </span>
          </template>

          <template #empty>
            <div class="tw-w-full tw-border-t tw-border-middle-gray tw-text-center tw-py-3 5xl:tw-py-[15px]">
              <span class="tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray">No data</span>
            </div>
          </template>
        </ui-row>
      </template>
    </ui-table>
  </div>
</template>

<script>
import { Tippy } from 'vue-tippy'
import 'tippy.js/dist/tippy.css'

import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { onMounted, computed } from 'vue'
import { getFormattingDate } from '@/utils/getFormattingDate'

export default {
  name: 'search-result',
  components: {
    Tippy
  },
  setup () {
    const route = useRoute()
    const store = useStore()

    onMounted(async () => {
      await store.dispatch('searchArticles', route.query.query)
    })

    const headers = [
      {
        column: 'UF_OEM',
        label: 'OEM number',
        size: 'tw-w-[9%] tw-min-w-[9%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray tw-text-sm tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_SUPPL_ID',
        label: 'Suppl ID',
        size: 'tw-w-[9%] tw-min-w-[9%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray tw-text-sm tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_PRICE',
        label: 'Price (CNY)',
        size: 'tw-w-[6%] tw-min-w-[6%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray tw-text-sm tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_COUNT',
        label: 'Count',
        size: 'tw-w-[5%] tw-min-w-[5%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray tw-text-sm tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_DATA',
        label: 'Date',
        size: 'tw-w-[8%] tw-min-w-[8%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray tw-text-sm tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_DESCRIPTION',
        label: 'Description',
        size: 'tw-w-[8%] tw-min-w-[8%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray tw-text-sm tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_AVAIBLE',
        label: 'Availability (stock / transit)',
        size: 'tw-w-[8%] tw-min-w-[8%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray tw-text-sm tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_STOCK_INFO',
        label: 'Stock info',
        size: 'tw-w-[9%] tw-min-w-[9%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray tw-text-sm tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_PRODUCTION_TIME',
        label: 'Production time',
        size: 'tw-w-[9%] tw-min-w-[9%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray tw-text-sm tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_ANALOGS',
        label: 'Where is used?',
        size: 'tw-w-[11%] tw-min-w-[11%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray tw-text-sm tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_SCHEMA',
        label: '',
        size: 'tw-w-[7%] tw-min-w-[7%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        classesItem: 'tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray tw-text-sm tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'HISTORY_PRICES',
        label: '',
        size: 'tw-w-[9%] tw-min-w-[9%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        classesItem: 'tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray tw-text-sm tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center',
        icon: null,
        onClick: () => {}
      }
    ]

    return {
      headers,
      isManager: computed(() => store.state.ProfileModule.user.role === 'manager'),
      getFormattingDate,
      searchResult: computed(() => store.state.searchResult)
    }
  }
}
</script>
