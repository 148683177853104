<template>
  <div
    v-if="userRole === roles.manager"
    class="sp-suppliers"
  >
    <ui-icon-base
      :icon="icons['fi-rr-users-alt']"
      :width="15"
      :height="15"
      :view-box-size="[15, 15]"
      fill="#454545"
      class="tw-w-5 tw-h-5 lg:tw-w-[15px] lg:tw-h-[15px] 5xl:tw-w-[18px] 5xl:tw-h-[18px]"
    />

    <sp-dropdown
      modal-id="suppliers"
      :model-value="selectedSupplier"
      :options="suppliers"
      option-label="name"
      placeholder="Suppliers"
      classes="ui-dropdown sp-suppliers__dropdown"
      label="Choose supplier"
      @update:model-value="onChangeSupplier($event)"
    >
      <template #modal-option="{ option, modelValue, close }">
        <div
          :class="[
            'tw-flex tw-items-center tw-w-full tw-py-3 tw-px-4 tw-rounded-[5px]',
            { 'tw-bg-orange-light-4' : modelValue?.id === option.id }
          ]"
        >
          <span
            :class="{ 'tw-text-orange tw-font-medium' : modelValue?.id === option.id }"
            @click="onChangeSupplierMobile(option, close)"
          >
            {{ option.name }}
          </span>
        </div>
      </template>
    </sp-dropdown>
  </div>
</template>

<script>
// Components
import SpDropdown from '@/modules/sp-dropdown/sp-dropdown.vue'

// Utils
import { storages } from '@/constants/storages'
import { roles } from '@/constants/roles'
import icons from '@/utils/icons'

import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'sp-suppliers',
  components: {
    SpDropdown
  },
  setup () {
    const store = useStore()
    const userRole = computed(() => store.state.ProfileModule.user.role)

    async function getPricesPending () {
      const { result = [], fileLink = null } = await store.dispatch('ArticlesModule/getPricesPending')
      const mappedArticles = result.map(item => ({ ...item, editable: true }))

      store.commit('ArticlesModule/setArticles', { articles: mappedArticles, targetArray: 'articlesPricePending' })
      store.commit('ArticlesModule/setTemplatePendingPricesLink', fileLink)
    }

    async function getArticles () {
      const { articles = [] } = await store.dispatch('ArticlesModule/getArticles')
      store.commit('ArticlesModule/setArticles', { articles, targetArray: 'articles' })
    }

    async function onChangeSupplier (supplier) {
      setSelectedSupplier(supplier)

      await getArticles()
      await getPricesPending()
      await store.dispatch('getCountInfo')
    }

    async function onChangeSupplierMobile (supplier, close) {
      close()
      await onChangeSupplier(supplier)
    }

    function setSelectedSupplier (supplier) {
      store.commit('SuppliersModule/setSelectedSupplier', supplier)
      sessionStorage.setItem(storages.selectedSupplier, supplier)
    }

    return {
      icons,
      onChangeSupplier,
      onChangeSupplierMobile,
      roles,
      selectedSupplier: computed(() => store.state.SuppliersModule.selectedSupplier),
      suppliers: computed(() => store.state.SuppliersModule.suppliers),
      userRole
    }
  }
}
</script>

<style lang="scss">
@import './sp-suppliers';
</style>
