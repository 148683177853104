import { ref } from 'vue'

export const selectArticlesMixin = () => {
  const selectedArticles = ref([])
  const selectAll = ref(false)

  function isSelected (oem, date) {
    return selectedArticles.value.some(element => element.UF_OEM === oem && parseInt(element.UF_DATA) === parseInt(date))
  }

  /**
   * Метод обработки выбора артикула
   * @param article
   * @param cb { function } callback
   */
  function onSelectArticle (article, cb = () => {}) {
    const { UF_OEM, UF_DATA } = article
    const isAdded = selectedArticles.value.findIndex(item => item.UF_OEM === UF_OEM && parseInt(item.UF_DATA) === parseInt(UF_DATA))

    if (isAdded === -1) {
      selectedArticles.value.push(article)
    } else {
      selectedArticles.value.splice(isAdded, 1)
    }

    cb()
  }

  // Метод для выбора всех артикулов
  function onSelectAll (articles) {
    if (selectAll.value) {
      selectedArticles.value = []
      selectAll.value = false
    } else {
      selectedArticles.value = JSON.parse(JSON.stringify(articles))
      selectAll.value = true
    }
  }

  function onResetSelect () {
    selectedArticles.value = []
    selectAll.value = false
  }

  return {
    isSelected,
    onSelectAll,
    onSelectArticle,
    onResetSelect,
    selectAll,
    selectedArticles
  }
}
