<template>
  <div
    v-if="router.currentRoute.value.meta.isProtected"
    class="tw-flex tw-flex-col tw-items-start tw-w-full tw-min-h-full"
  >
    <main class="sp-main-wrapper tw-relative">
      <sp-side-menu v-if="breakpointGreaterOrEqualLg" />
      <sp-mobile-menu v-else />

      <div class="sp-main-container tw-gap-6 5xl:tw-gap-[30px]">
        <div class="tw-flex tw-items-center tw-gap-3 tw-w-full">
          <ui-input
            :model-value="searchQuery"
            :pt="{ root: { class: ['ui-input', 'ui-input_search'] } }"
            :merge-pt="true"
            :classes="['ui-input_icon-left']"
            name="search"
            class="md:tw-max-w-[420px] md:tw-w-full"
            placeholder="Search"
            @update:model-value="searchQuery = $event"
            @keydown.enter="onSearching"
          >
            <template #icon-before>
              <ui-icon-base
                :icon="icons['search']"
                :width="16"
                :height="16"
                :view-box-size="[16, 16]"
                fill="#454545"
              />
            </template>
          </ui-input>

          <ui-button
            value="Search"
            class="ui-button_md ui-button_primary"
            @click="onSearching"
          />
        </div>

        <router-view />
      </div>
    </main>

    <modals-container />
  </div>

  <router-view v-else />
  <toast
    position="bottom-right"
    class="sp-toast"
  />
</template>

<script>
import SpMobileMenu from '@/modules/sp-mobile-menu/sp-mobile-menu.vue'
import SpSideMenu from '@/modules/sp-side-menu/sp-side-menu.vue'
import Toast from 'primevue/toast'

import icons from '@/utils/icons'
import { useBreakpoints, breakpointsTailwind } from '@vueuse/core'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { ModalsContainer } from 'vue-final-modal'
import { ref, onMounted } from 'vue'

export default {
  name: 'app',
  components: {
    ModalsContainer,
    SpMobileMenu,
    SpSideMenu,
    Toast
  },
  setup () {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const searchQuery = ref('')

    async function onSearching () {
      if (route.name === 'Search') {
        await store.dispatch('searchArticles', searchQuery.value)
      } else {
        await router.push({
          path: '/search',
          query: {
            query: searchQuery.value
          }
        })
      }
    }

    onMounted(async () => {
      const urlParams = new URLSearchParams(window.location.hash.split('?')[1])
      searchQuery.value = urlParams.get('query')
    })

    const breakpoints = useBreakpoints({
      ...breakpointsTailwind,
      '2xl': '1440px',
      '3xl': '1696px',
      '4xl': '1920px'
    })
    const breakpointGreaterOrEqualLg = breakpoints.greaterOrEqual('lg')

    return {
      breakpointGreaterOrEqualLg,
      icons,
      onSearching,
      router,
      searchQuery
    }
  }
}
</script>
