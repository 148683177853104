<template>
  <div class="tw-flex tw-flex-col tw-items-start tw-w-full tw-max-w-[800px] 5xl:tw-max-w-[1000px] tw-gap-6 5xl:tw-gap-[30px]">
    <div class="tw-flex tw-items-center tw-justify-between tw-w-full">
      <h2 class="tw-text-2xl lg:tw-text-3xl 5xl:tw-text-37px] tw-font-medium tw-text-black">My profile</h2>

      <ui-button
        value="Сохранить"
        :class="[
            'ui-button_md ui-button_primary tw-hidden md:tw-flex',
            { 'ui-button_disabled' : !isChanged }
          ]"
        @click="onSaveUserData"
      />
    </div>

    <div class="tw-flex tw-flex-col tw-w-full tw-items-start tw-gap-6 5xl:tw-gap-[30px]">
      <div
        v-for="(item, index) in blocks"
        :key="index"
        class="tw-flex tw-flex-col md:tw-flex-row tw-items-start tw-justify-between tw-w-full tw-gap-4 5xl:tw-gap-5 tw-pb-6 5xl:tw-pb-[30px] tw-border-b tw-border-b-middle-gray last:tw-border-none"
      >
        <div class="tw-flex tw-flex-col tw-items-start tw-w-auto tw-gap-2 5xl:tw-gap-[10px]">
          <h3 class="tw-text-base 5xl:tw-text-xl tw-font-medium tw-text-black">{{ item.title }}</h3>
          <span class="tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray">{{ item.subtitle }}</span>
        </div>

        <div class="tw-flex tw-flex-col tw-w-full md:tw-max-w-[500px] lg:tw-max-w-[392px] 5xl:tw-max-w-[490px] tw-gap-4 5xl:tw-gap-5">
          <div
            v-for="(field, key) in item.fields"
            :key="key"
            class="tw-w-full"
          >
            <ui-input
              v-if="field.element === componentTypes.INPUT"
              :model-value="field.modelValue"
              :name="field.name"
              :placeholder="field.placeholder"
              :classes="field.classes"
              class="tw-items-start tw-gap-[6px]"
              @update:model-value="onUpdateProfileData({ key: field.name, value: $event })"
            >
              <template
                v-if="field.label"
                #label
              >
                <span class="tw-text-sm 5xl:tw-text-[17px] tw-font-medium tw-text-dark-gray">{{ field.label }}</span>
              </template>
            </ui-input>

            <ui-dropdown
              v-if="field.element === componentTypes.SELECT"
              :model-value="field.modelValue"
              :options="countries"
              :placeholder="field.placeholder"
              option-label="name"
              option-value="code"
              class="tw-border"
              @update:value="onUpdateProfileData({ key: field.name, value: $event })"
            />
          </div>
        </div>
      </div>
    </div>

    <ui-button
      value="Сохранить"
      :class="[
        'ui-button_md ui-button_primary tw-flex md:tw-hidden tw-w-full',
        { 'ui-button_disabled' : !isChanged }
      ]"
      @click="onSaveUserData"
    />
  </div>
</template>

<script>
import componentTypes from '@/constants/component-types'
import { inputTypes } from '@/constants/input-types'
import { countries } from '@/constants/countries'

import { ref, reactive, computed } from 'vue'
import { useStore } from 'vuex'
import { useToast } from 'primevue/usetoast'

export default {
  name: 'profile',
  setup () {
    const toast = useToast()
    const store = useStore()
    /**
     * Inputs and Dropdown data
     */
    const blocks = reactive([
      {
        title: 'Personal information',
        subtitle: 'Update your personal info',
        fields: [
          {
            label: 'First name',
            placeholder: 'First name',
            name: 'firstName',
            modelValue: computed(() => store.state.ProfileModule.user.firstName),
            type: inputTypes.TEXT,
            element: componentTypes.INPUT,
            classes: ''
          },
          {
            label: 'Last name',
            placeholder: 'Last name',
            name: 'lastName',
            modelValue: computed(() => store.state.ProfileModule.user.lastName),
            type: inputTypes.TEXT,
            element: componentTypes.INPUT,
            classes: ''
          }
        ]
      },
      {
        title: 'Contact information',
        subtitle: 'Update your contact info',
        fields: [
          {
            label: 'E-mail',
            placeholder: 'E-mail',
            name: 'email',
            modelValue: computed(() => store.state.ProfileModule.user.email),
            type: inputTypes.TEXT,
            element: componentTypes.INPUT,
            classes: ''
          },
          {
            label: 'Phone number',
            placeholder: 'Phone number',
            name: 'phone',
            modelValue: computed(() => store.state.ProfileModule.user.phone),
            type: inputTypes.TEXT,
            element: componentTypes.INPUT,
            classes: ''
          }
        ]
      },
      {
        title: 'Company information',
        subtitle: 'Update your company info',
        fields: [
          {
            label: 'Company',
            placeholder: 'Company name',
            name: 'company',
            modelValue: computed(() => store.state.ProfileModule.user.company),
            type: inputTypes.TEXT,
            element: componentTypes.INPUT,
            classes: ''
          },
          {
            label: 'Country',
            placeholder: 'Country',
            name: 'country',
            modelValue: computed(() => store.state.ProfileModule.user.country),
            element: componentTypes.SELECT,
            classes: ''
          }
        ]
      },
      {
        title: 'Password',
        subtitle: 'Change your password',
        fields: [
          {
            label: null,
            placeholder: 'Password',
            name: 'password',
            modelValue: '',
            type: inputTypes.PASSWORD,
            element: componentTypes.INPUT,
            classes: 'ui-input_disable'
          },
          {
            label: null,
            placeholder: 'Repeat password',
            name: 'repeat-password',
            modelValue: '',
            type: inputTypes.PASSWORD,
            element: componentTypes.INPUT,
            classes: 'ui-input_disable'
          }
        ]
      }
    ])
    const isChanged = ref(false)

    function onUpdateProfileData ({ key, value }) {
      store.commit('ProfileModule/updateProfileData', { key, value })

      if (!isChanged.value) {
        isChanged.value = true
      }
    }

    async function onSaveUserData () {
      await store.dispatch('ProfileModule/saveUserData')
      toast.add({
        detail: 'User data has been updated',
        life: 3000
      })
    }

    return {
      blocks,
      componentTypes,
      countries,
      isChanged,
      onSaveUserData,
      onUpdateProfileData
    }
  }
}
</script>
