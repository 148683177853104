import * as Excel from 'exceljs'
import * as FileSaver from 'file-saver'

class GenerateXlsx {
  /**
   * @param { Object[] } [headers=null] - Массив объектов, где каждый объект является колонкой и хранит в себе название колонки (header) и
   * ключ (key) по которому будут браться данные из массива объектов items
   * @param { Object[] } [items=null] - Массив элементов таблицы
   */
  constructor (headers = null, items = null) {
    this.headers = headers
    this.items = items
  }

  /**
   * @param { String } name - Имя файла
   * @return { string } - Возвращает строку из имени компании и текущей даты
   */
  createFilename (name) {
    return `${name}_${new Date().toLocaleString().slice(0, 10)}.xlsx`
  }

  /**
   * Метод возвращает массив объектов для создания простой excel таблицы без разделения
   * по категориям.
   * @return { column, cells }
   */
  createSimpleSheet (key = 'key') {
    const formattedCells = this.items.map(item =>
      this.headers.map(header => item[header[key]])
    )

    return {
      columns: this.headers,
      cells: formattedCells
    }
  }

  /**
   * Метод для генерации xlsx. Принимает имя рабочего листа, колонки и строки.
   * @param { String } filename - Название файла
   * @param { String } worksheetName - Название рабочего листа
   * @param { Object[] } columns - Колонки
   * @param { Object[] } cells - Строки
   * @return {Promise<void>}
   */

  async generateXlsx (filename, worksheetName = 'List 1', { columns, cells }) {
    const workbook = new Excel.Workbook()
    const worksheet = workbook.addWorksheet(worksheetName)

    worksheet.columns = columns
    worksheet.addRows(cells)

    // Все строки у которых массивы с данными по длине меньше чем
    // массив колонок мы считаем как строки с названием категории.
    // Для них выставляем стили чтобы выделить их в файле.
    const subtitleRows = worksheet.getRows(0, cells.length) || []
    const filteredSubtitleRows = subtitleRows.filter(
      row => row.values.length <= columns.length
    )

    filteredSubtitleRows.forEach(row => {
      row.font = {
        bold: true,
        size: 14,
        color: {
          argb: 'FFFFFF'
        }
      }

      row.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {
          argb: 'ea5239'
        }
      }
    })
    // Так как первая строка у нас содержит названия колонок, то
    // прописываем для них стили.
    worksheet.getRow(1).font = {
      bold: true,
      size: 14,
      color: {
        argb: 'FFFFFF'
      }
    }
    worksheet.getRow(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {
        argb: 'ea5239'
      }
    }

    const file = await workbook.xlsx.writeBuffer()
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    const blob = new Blob([file], { type: fileType })

    await FileSaver.saveAs(blob, filename)
  }
}

export default GenerateXlsx
