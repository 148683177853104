export const getFormattingDate = (timestamp) => {
  if (!timestamp) return ''

  const jsTimestamp = timestamp * 1000
  const date = new Date(jsTimestamp)
  const day = date.getDate().toString().length > 1 ? date.getDate() : `0${date.getDate()}`
  const month = (date.getMonth() + 1).toString().length > 1 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`

  return `${day}.${month}.${date.getFullYear()}`
}
