import axios from 'axios'
import store from '@/store/index'
import router from '@/router/index'
import { storages } from '@/constants/storages'
import { BASE_URL } from './config'

class Api {
  #client
  #router

  constructor () {
    this.#client = axios.create({ baseURL: BASE_URL })
  }

  async execute (method, url, { params = {}, data = {} }) {
    try {
      const token = store.state.ProfileModule.token || JSON.parse(localStorage.getItem(storages.userToken))
      const headers = {
        'Content-Type': 'text/plain',
        Authorization: `Basic ${token}`
      }
      const options = {
        method,
        url,
        params,
        data,
        headers
      }
      const result = await this.#client(options)

      return result?.data || {}
    } catch (error) {
      console.error(error)

      if (error.request.status === 401) {
        await router.push('/auth')
      }
    }
  }

  get (url, { params = {} }) {
    return this.execute('get', url, { params })
  }

  post (url, { params = {}, data = {} }) {
    return this.execute('post', url, { params, data })
  }

  put (url, { params = {}, data = {} }) {
    return this.execute('put', url, { params, data })
  }

  delete (url, { data = {} }) {
    return this.execute('delete', url, { data })
  }
}

export default new Api()
