<template>
  <div class="tw-flex tw-flex-col tw-items-start tw-w-full tw-gap-5 md:tw-gap-6 5xl:tw-gap-[30px]">
    <h2 class="tw-text-2xl md:tw-text-3xl 5xl:tw-text-[37.5px] tw-font-semibold tw-text-black">What would you like to do on the portal:</h2>

    <div class="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-w-full tw-gap-6 5xl:tw-gap-[30px] 5xl:tw-max-w-[1200px] 5xl:tw-mx-auto">
      <ui-button
        v-for="(item, index) in cards"
        :key="index"
        :href="item.href"
        tag="div"
        class="ui-button_tag tw-justify-start tw-h-auto tw-py-5 5xl:tw-py-[25px] tw-px-6 5xl:tw-px-[30px] md:tw-px-10 xl:tw-px-20"
        @click="item.onClick"
      >
        <template #default>
          <div class="tw-flex tw-items-center tw-gap-8 5xl:tw-gap-10 tw-py-3 lg:tw-py-9 5xl:tw-py-[45px]">
            <div class="tw-min-w-[43px] 5xl:tw-min-w-[53px]">
              <ui-icon-base
                :icon="icons[item.icon.name]"
                :width="item.icon.width"
                :height="item.icon.height"
                :view-box-size="item.icon.viewBoxSize"
              />
            </div>

            <span class="tw-text-xl 5xl:tw-text-[25px] tw-font-semibold tw-text-left tw-max-w-[240px] 5xl:tw-max-w-full">{{ item.text }}</span>
          </div>
        </template>
      </ui-button>
    </div>
  </div>
</template>

<script>
import icons from '@/utils/icons'

import { useStore } from 'vuex'
import { computed, ref } from 'vue'

export default {
  name: 'home',
  setup () {
    const store = useStore()

    const priceInPendingCounts = computed(() => store.state.articlePricesPending)
    const cards = ref([
      {
        href: '/upload-articles',
        text: 'Upload your nomenclature offer',
        icon: {
          name: 'ff-rr-upload',
          width: 44,
          height: 42,
          viewBoxSize: [44, 42]
        }
      },
      {
        href: '/articles',
        text: 'History of uploaded articles',
        icon: {
          name: 'fi-rr-time-forward',
          width: 42,
          height: 42,
          viewBoxSize: [42, 42]
        }
      },
      {
        href: '/prices-in-pending',
        text: `Prices pending (${priceInPendingCounts.value})`,
        icon: {
          name: 'fi-rr-hourglass-end',
          width: 42,
          height: 42,
          viewBoxSize: [42, 42]
        }
      },
      {
        href: '/profile',
        text: 'Profile',
        icon: {
          name: 'fi-rr-mode-portrait',
          width: 42,
          height: 42,
          viewBoxSize: [42, 42]
        }
      }
    ])

    return {
      cards,
      icons
    }
  }
}
</script>

<style lang="scss">
.ui-button_tag:hover {
  svg {
    path {
      transition: .25s ease-in-out;

      &[fill] {
        fill: #FFF;
      }

      &[stroke] {
        stroke: #FFF;
      }
    }
  }
}
</style>
