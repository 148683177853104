<template>
  <div class="tw-flex tw-flex-wrap tw-items-start tw-justify-between tw-w-full lg:tw-pl-4 5xl:tw-pl-5 lg:tw-py-3 5xl:tw-py-[15px] tw-gap-y-12">
    <div class="tw-flex tw-flex-col lg:tw-flex-row tw-items-start lg:tw-items-center tw-justify-between tw-w-full tw-gap-6 5xl:tw-gap-[30px]">
      <div class="tw-flex lg:tw-flex-wrap tw-items-center tw-gap-11 5xl:tw-gap-[55px] tw-py-[2.5px]">
        <div class="tw-flex tw-items-center tw-w-auto tw-gap-3 5xl:tw-gap-[15px] tw-max-w-[210px] 5xl:tw-max-w-[262px]">
          <span class="tw-text-2xl lg:tw-text-3xl 5xl:tw-text-[37px] tw-font-medium tw-text-orange">{{ articleCounts }}</span>
          <span class="tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray tw-text-left tw-leading-4">article has been uploaded successfully</span>
        </div>

        <router-link
          to="/prices-in-pending"
          tag="div"
          class="tw-flex tw-items-center tw-w-auto tw-gap-3 5xl:tw-gap-[15px] tw-max-w-[210px] 5xl:tw-max-w-[262px] tw-cursor-pointer"
        >
          <span class="tw-text-2xl lg:tw-text-3xl 5xl:tw-text-[37px] tw-font-medium tw-text-orange">{{ articlePricesPending }}</span>
          <span class="tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray tw-text-left tw-leading-4 hover:tw-text-orange tw-transition tw-duration-100">article in anticipation of the price</span>
        </router-link>
      </div>

      <div class="tw-w-full lg:tw-w-[300px] 5xl:tw-w-[375px]">
        <ui-input
          name="search-article-by-oem"
          placeholder="Search article by OEM"
          :model-value="search"
          @update:model-value="search = $event"
        />
      </div>
    </div>

    <div class="tw-flex tw-flex-col tw-gap-10 5xl:tw-gap-[50px] tw-w-full tw-relative tw-min-h-[250px]">
      <ui-loader :loading="loading" />

      <div
        v-if="filteredArticles.length"
        class="tw-flex tw-flex-col tw-w-full tw-gap-7 5xl:tw-gap-[35px]"
      >
        <tables
          v-for="(item, index) in filteredArticles"
          :key="index"
          :date="item.date"
          :items="item.items"
          :headers="headers"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Tables from '@/views/articles/components/tables'

import { selectArticlesMixin } from '@/mixins/select-articles.mixin'

import { onMounted, computed, ref } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'articles',
  components: {
    Tables
  },
  setup () {
    const store = useStore()

    const articles = computed(() => store.state.ArticlesModule.articles)
    const articleCounts = computed(() => store.state.articleCounts)
    const articlePricesPending = computed(() => store.state.articlePricesPending)
    const loading = ref(false)
    const headers = [
      {
        column: 'remove-action',
        label: '',
        size: 'tw-w-[3.5%] tw-min-w-[3.5%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-pl-0 lg:tw-pl-6 tw-pr-0 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray tw-text-sm tw-py-3 tw-px-6 tw-pl-0 lg:tw-pl-6 tw-pr-0 tw-min-h-[72px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_OEM',
        label: 'OEM number',
        size: 'tw-w-[10%] tw-min-w-[10%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray tw-text-sm tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_SUPPL_ID',
        label: 'Suppl ID',
        size: 'tw-w-[10%] tw-min-w-[10%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray tw-text-sm tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_PRICE',
        label: 'Price (CNY)',
        size: 'tw-w-[7%] tw-min-w-[7%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray tw-text-sm tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_COUNT',
        label: 'Count',
        size: 'tw-w-[6%] tw-min-w-[6%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray tw-text-sm tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_DATA',
        label: 'Date',
        size: 'tw-w-[6%] tw-min-w-[6%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray tw-text-sm tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_DESCRIPTION',
        label: 'Description',
        size: 'tw-w-[10%] tw-min-w-[10%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray tw-text-sm tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_AVAIBLE',
        label: 'Availability (stock / transit)',
        size: 'tw-w-[10%] tw-min-w-[10%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray tw-text-sm tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_STOCK_INFO',
        label: 'Stock info',
        size: 'tw-w-[9%] tw-min-w-[9%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray tw-text-sm tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_PRODUCTION_TIME',
        label: 'Production time',
        size: 'tw-w-[9%] tw-min-w-[9%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray tw-text-sm tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_ANALOGS',
        label: 'Where is used?',
        size: 'tw-w-[11%] tw-min-w-[11%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray tw-text-sm tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_SCHEMA',
        label: '',
        size: 'tw-w-[7%] tw-min-w-[7%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        classesItem: 'tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray tw-text-sm tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center',
        icon: null,
        onClick: () => {}
      }
    ]

    /**
     * Search
     */
    const search = ref('')
    const filteredArticles = computed(() => {
      if (!articles.value.length) {
        return []
      }

      const copyOfArticles = JSON.parse(JSON.stringify(articles.value))

      return copyOfArticles.map(item => {
        if (!item?.items) return item

        item.items = item.items.filter(element => element.UF_OEM.includes(search.value) || !search.value.length)
        return item
      })
    })

    onMounted(async () => {
      loading.value = true
      await getArticles()
      loading.value = false
    })

    async function getArticles () {
      const { articles = [] } = await store.dispatch('ArticlesModule/getArticles')
      store.commit('ArticlesModule/setArticles', { articles, targetArray: 'articles' })
    }

    return {
      articles,
      articleCounts,
      articlePricesPending,
      filteredArticles,
      headers,
      loading,
      search,
      ...selectArticlesMixin()
    }
  }
}
</script>
