<template>
  <div class="sp-menu-mobile">
    <ui-icon-base
      :icon="icons['burger']"
      :width="20"
      :height="14"
      :view-box-size="[20, 14]"
      fill="#454545"
      @click="isOpen = true"
    />

    <router-link to="/" class="tw-mx-auto">
      <ui-icon-base
        :icon="icons['logo']"
        :width="177"
        :height="49"
        :view-box-size="[177, 49]"
      />
    </router-link>
  </div>

  <div
    :class="[
      'sp-menu-mobile__container',
      { 'sp-menu-mobile__container_open' : isOpen }
    ]"
  >
    <div class="sp-menu-mobile__wrapper tw-gap-10">
      <router-link
        to="/"
        class="tw-px-[23px]"
        @click="isOpen = false"
      >
        <ui-icon-base
          :icon="icons['logo']"
          :width="177"
          :height="49"
          :view-box-size="[177, 49]"
        />
      </router-link>

      <div class="tw-flex tw-flex-col tw-w-full tw-gap-10 tw-px-4">
        <nav class="tw-flex tw-flex-col tw-w-full tw-gap-[50px] tw-px-[7px]">
          <div
            v-for="(item, index) in menu"
            :key="index"
            class="tw-flex tw-flex-col tw-items-start tw-w-full tw-gap-6"
          >
            <sp-navigation
              v-for="({ url, text, icon }, index) in item.items"
              :key="index"
              :url="url"
              :text="text"
              @click="isOpen = false"
            >
              <template #icon>
                <ui-icon-base
                  :icon="icons[icon.name]"
                  :width="icon.width"
                  :height="icon.height"
                  :view-box-size="icon.viewBoxSize"
                  :fill="icon.fill"
                  class="sp-navigation__item-icon"
                />
              </template>
            </sp-navigation>
          </div>
        </nav>

        <sp-suppliers />
      </div>

      <div class="tw-flex tw-px-4 tw-w-full tw-mt-auto">
        <sp-user />
      </div>
    </div>

    <ui-icon-base
      :icon="icons['close']"
      :width="15"
      :height="15"
      :view-box-size="[15, 15]"
      :stroke-width="2"
      stroke-linecap="round"
      stroke="#fff"
      class="sp-menu-mobile__close"
      @click="isOpen = false"
    />
  </div>
</template>

<script>
import SpNavigation from '@/modules/sp-side-menu/components/sp-navigation/sp-navigation.vue'
import SpSuppliers from '@/modules/sp-suppliers/sp-suppliers.vue'
import SpUser from '@/modules/sp-side-menu/components/sp-user.vue'
import icons from '@/utils/icons'

import { menu } from '@/mixins/menu.mixin'
import { ref } from 'vue'

/**
 * Модуль для отрисовки мобильного меню
 * @displayName EpMobileMenu
 */
export default {
  name: 'sp-mobile-menu',
  components: {
    SpNavigation,
    SpSuppliers,
    SpUser
  },
  setup () {
    const isOpen = ref(false)

    return {
      icons,
      isOpen,
      ...menu()
    }
  }
}
</script>

<style lang="scss">
@import './sp-mobile-menu';
</style>
