import { createRouter, createWebHashHistory } from 'vue-router'

import AddPriceInPending from '@/views/add-price-in-pending'
import Articles from '@/views/articles/articles.vue'
import Auth from '@/views/auth'
import Home from '@/views/home'
import PricesInPending from '@/views/prices-in-pending'
import Profile from '@/views/profile'
import SearchResult from '@/views/search-result.vue'
import UploadArticles from '@/views/upload-articles'

import { roles } from '@/constants/roles'
import middleware from '@/router/middleware'

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    name: 'auth',
    path: '/auth',
    component: Auth,
    meta: {
      isProtected: false
    }
  },
  {
    name: 'home',
    path: '/home',
    component: Home,
    meta: {
      allowedRoles: [roles.supplier, roles.manager],
      isProtected: true
    }
  },
  {
    path: '/upload-articles',
    name: 'upload-articles',
    component: UploadArticles,
    meta: {
      allowedRoles: [roles.supplier, roles.manager],
      isProtected: true
    }
  },
  {
    path: '/articles',
    name: 'Articles',
    component: Articles,
    meta: {
      allowedRoles: [roles.supplier, roles.manager],
      isProtected: true
    }
  },
  {
    path: '/prices-in-pending',
    name: 'Prices in pending',
    component: PricesInPending,
    meta: {
      allowedRoles: [roles.supplier, roles.manager],
      isProtected: true
    }
  },
  {
    path: '/profile',
    name: 'Profile page',
    component: Profile,
    meta: {
      allowedRoles: [roles.supplier, roles.manager],
      isProtected: true
    }
  },
  {
    path: '/add-price-in-pending',
    name: 'Add price in pending',
    component: AddPriceInPending,
    meta: {
      allowedRoles: [roles.manager],
      isProtected: true
    }
  },
  {
    name: 'Search',
    path: '/search',
    component: SearchResult,
    meta: {
      allowedRoles: [roles.manager, roles.supplier],
      isProtected: true
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(middleware)

export default router
