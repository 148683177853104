<template>
  <div class="tw-flex tw-items-center tw-justify-between tw-w-full tw-py-5 tw-pl-2 tw-border-t tw-border-middle-gray">
    <div class="tw-flex tw-flex-col tw-items-start">
      <router-link
        to="/profile"
        tag="span"
        class="tw-text-sm max-[1024px]:tw-text-base tw-font-medium tw-text-dark-gray"
      >
        {{ user }}
      </router-link>
    </div>

    <div
      class="tw-w-[35px] tw-h-[31px] tw-p-2 tw-cursor-pointer"
      @click="onLogout"
    >
      <ui-icon-base
        :icon="icons['logout']"
        :width="15"
        :height="15"
        :view-box-size="[15, 15]"
        fill="#454545"
      />
    </div>
  </div>
</template>

<script>
import icons from '@/utils/icons'

import { useStore } from 'vuex'
import { computed } from 'vue'
import { useRouter } from 'vue-router'

/**
 * Компонент для отрисовки пользователя. Исопльзуется только в компоненте EpSideMenu
 * @displayName EpUser
 */
export default {
  name: 'sp-user',
  setup () {
    const router = useRouter()
    const store = useStore()

    const user = computed(() => `${store.state.ProfileModule.user?.firstName} ${store.state.ProfileModule.user?.lastName}`)

    function onLogout () {
      store.commit('ProfileModule/logout')
      router.push('/auth?logout=yes')
    }

    return {
      icons,
      onLogout,
      user
    }
  }
}
</script>
