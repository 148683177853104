<template>
  <router-link
    :to="url"
    class="sp-navigation__item"
    active-class="sp-navigation__item_active"
  >
    <slot name="icon" />
    <span class="sp-navigation__item-text">{{ text }}</span>
  </router-link>
</template>

<script>
export default {
  name: 'sp-navigation',
  props: {
    text: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
@import './sp-navigation';
</style>
