import { storages } from '@/constants/storages'
import store from '@/store/index'

export default async (to, from, next) => {
  if (to.meta.isProtected) {
    const userData = JSON.parse(localStorage.getItem(storages.userData))
    const token = JSON.parse(localStorage.getItem(storages.userToken))

    if (!token) {
      next('/auth')
    }

    store.commit('ProfileModule/setUser', userData)
    store.commit('ProfileModule/setToken', token)

    if (userData.role === 'manager') {
      const { supplies } = await store.dispatch('SuppliersModule/getSuppliers')
      store.commit('SuppliersModule/setSuppliers', supplies)
    }

    if (to.meta.allowedRoles.includes(userData.role)) {
      await store.dispatch('getCountInfo')
      next()
    } else {
      next('/home')
    }
  } else {
    next()
  }
}
