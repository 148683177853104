<template>
  <ui-upload
    :class="[
      'tw-w-full',
      { 'tw-pointer-events-none' : loading },
      { 'tw-opacity-50 tw-pointer-events-none' : disabled }
    ]"
    :allowed-extensions="allowedExtensions"
    @upload:file="onUploadFileHandler($event)"
  >
    <template #default>
      <div
        :class="[
          'sp-upload-files tw-relative tw-overflow-hidden',
          { 'sp-upload-files_active': active }
        ]"
        @mouseenter="onMouseEnterHandler"
        @mouseleave="onMouseLeaveHandler"
      >
        <div class="sp-upload-files__icon">
          <ui-icon-base
            :icon="icons['upload']"
            :width="22"
            :height="22"
            :view-box-size="[22, 22]"
            class="5xl:tw-w-[27px] 5xl:tw-h-[27px]"
          />
        </div>

        <div class="sp-upload-files__content">
          <p class="tw-text-base 5xl:tw-text-xl tw-font-regular tw-leading-[24px] tw-text-dark-gray">
            <span class="tw-font-bold tw-text-orange">Click to upload</span>
            <span class="tw-hidden lg:tw-flex">or drag and drop</span>
          </p>

          <span class="tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-leading-[24px] tw-text-dark-gray">XLS, XLSX, JSON, XML or CSV</span>
        </div>

        <div
          class="tw-absolute tw-w-full tw-h-full tw-cursor-pointer tw-inset-0"
          @drop="onDropHandler($event)"
          @dragover="onDragOverHandler($event)"
          @dragenter="onDragEnterHandler($event)"
          @dragleave="onDragLeaveHandler($event)"
        ></div>

        <ui-loader :loading="loading" />
      </div>
    </template>
  </ui-upload>
</template>

<script>
import icons from '@/utils/icons'

import { ref, toRefs } from 'vue'

export default {
  name: 'sp-upload-files',
  props: {
    allowedExtensions: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['upload:file'],
  setup (props, { emit }) {
    const { loading } = toRefs(props)
    const active = ref(false)

    // Drag & drop handlers
    function onDropHandler (event) {
      event.stopPropagation()
      event.preventDefault()

      if (loading.value) return

      onDragLeaveHandler()
      onUploadFileHandler(event.dataTransfer.files)
    }

    function onDragEnterHandler () {
      if (loading.value) return

      active.value = true
    }

    function onDragLeaveHandler () {
      active.value = false
    }

    function onDragOverHandler (event) {
      event.preventDefault()
    }

    // Mouseenter & mouseleave handlers
    function onMouseEnterHandler () {
      if (loading.value) return
      active.value = true
    }

    function onMouseLeaveHandler () {
      active.value = false
    }

    function onUploadFileHandler (file) {
      emit('upload:file', file)
    }

    return {
      active,
      icons,
      onDragEnterHandler,
      onDragLeaveHandler,
      onDropHandler,
      onDragOverHandler,
      onMouseEnterHandler,
      onMouseLeaveHandler,
      onUploadFileHandler
    }
  }
}
</script>

<style lang="scss">
@import './sp-upload-files';
</style>
