<template>
  <div class="tw-flex tw-flex-col tw-items-center tw-max-w-[396px] sm:tw-max-w-[360px] 5xl:tw-max-w-[450px] tw-px-4 sm:tw-px-0 tw-my-auto tw-mx-auto tw-gap-[32px] 5xl:tw-gap-10">
    <div class="tw-flex tw-flex-col tw-w-full tw-items-center tw-gap-6 5xl:tw-gap-[30px]">
      <ui-icon-base
        :icon="icons['logo-mini']"
        :width="42"
        :height="48"
        :view-box-size="[42, 48]"
      />

      <div class="tw-flex tw-flex-col tw-items-center tw-gap-3 5xl:tw-gap-[15px]">
        <p class="tw-text-[24px] sm:tw-text-[30px] 5xl:tw-gap-[37.5px] tw-font-medium tw-text-black tw-leading-[38px] tw-text-center">Log in to your account</p>
        <p class="tw-text-base 5xl:tw-text-xl tw-font-normal tw-text-gray tw-text-center">Welcome to Eparts! Please enter your details.</p>
      </div>
    </div>

    <form
      class="tw-flex tw-flex-col tw-w-full tw-gap-6 5xl:tw-gap-[30px]"
      @submit.prevent="onSignIn"
    >
      <div class="tw-flex tw-flex-col tw-items-start tw-gap-5 5xl:tw-gap-[25px]">
        <ui-input
          v-for="(input, index) in inputs"
          :key="index"
          :model-value="input.value"
          :name="input.name"
          :type="input.type"
          :placeholder="input.placeholder"
          :classes="[{ 'ui-input_error' : isError }]"
          class="tw-items-start tw-gap-[6px] tw-relative"
          @focus="isError = false"
          @update:model-value="input.value = $event"
          @keydown.enter="input.keydown($event)"
        >
          <template #label>
            <span class="tw-text-sm 5xl:tw-gap-[17.5px] tw-font-medium tw-text-dark-gray">{{ input.label }}</span>
          </template>

          <template
            v-if="input.name === 'password'"
            #icon-after
          >
            <ui-icon-base
              :icon="icons[getPasswordIconName]"
              :width="16"
              :height="14"
              :view-box-size="[16, 14]"
              fill="#6C6D70"
              class="ui-input__password"
              @click="changePasswordVisibility"
            />
          </template>
        </ui-input>
      </div>

      <div class="tw-flex tw-items-center tw-justify-between tw-w-full">
        <label class="tw-flex tw-items-center tw-gap-2 5xl:tw-gap-[10px] tw-cursor-pointer">
          <ui-checkbox
            :value="rememberOnThisComputer"
            :classes="['ui-checkbox_base']"
            @update:value="rememberOnThisComputer = $event"
          />

          <span class="tw-text-sm 5xl:tw-gap-[17.5px] tw-font-medium tw-text-dark-gray tw-leading-[100%]">Remember on this computer</span>
        </label>
      </div>

      <div class="tw-flex tw-flex-col tw-items-start tw-w-full tw-gap-4 5xl:tw-gap-5">
        <ui-button
          class="ui-button_md ui-button_w-full ui-button_primary"
          value="Sign in"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { inputTypes } from '@/constants/input-types'
import { storages } from '@/constants/storages'
import icons from '@/utils/icons'

import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useToast } from 'primevue/usetoast'
import { useRouter } from 'vue-router'

export default {
  name: 'auth',
  setup () {
    const store = useStore()
    const router = useRouter()
    const toast = useToast()

    // Переменные для ui-input-ов
    const isVisiblePassword = ref(false)
    const getPasswordIconName = computed(() => {
      return isVisiblePassword.value ? 'hide-password' : 'show-password'
    })
    const inputs = ref([
      {
        label: 'Login',
        type: inputTypes.TEXT,
        name: 'login',
        placeholder: 'Enter your login',
        value: '',
        icon: null,
        keydown: () => {}
      },
      {
        label: 'Password',
        type: isVisiblePassword.value ? inputTypes.TEXT : inputTypes.PASSWORD,
        name: 'password',
        placeholder: 'Enter your password',
        value: '',
        icon: {
          name: getPasswordIconName,
          width: 15,
          height: 13,
          viewBoxSize: [16, 13],
          fill: '#6C6D70'
        },
        keydown: () => onSignIn()
      }
    ])
    const isError = ref(false)

    const rememberOnThisComputer = ref(false)

    async function onSignIn () {
      const {
        success = false,
        token = null,
        message = 'Some error'
      } = await store.dispatch('ProfileModule/login', {
        login: inputs.value[0].value,
        password: inputs.value[1].value
      })

      if (success) {
        store.commit('ProfileModule/setToken', token)
        localStorage.setItem(storages.userToken, JSON.stringify(token))

        await store.dispatch('ProfileModule/getProfileData')
        await router.push('/home')
      } else {
        isError.value = true
        toast.add({
          detail: message,
          life: 3000,
          severity: 'error'
        })
      }
    }

    function changePasswordVisibility () {
      const password = inputs.value.find(item => item.name === 'password')

      isVisiblePassword.value = !isVisiblePassword.value
      password.type = isVisiblePassword.value ? inputTypes.TEXT : inputTypes.PASSWORD
    }

    return {
      changePasswordVisibility,
      getPasswordIconName,
      icons,
      inputs,
      inputTypes,
      isError,
      onSignIn,
      rememberOnThisComputer
    }
  }
}
</script>
