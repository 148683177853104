import { createStore } from 'vuex'
import Api from '@/api'

import ArticlesModule from './modules/articles.module'
import ProfileModule from './modules/profile.module'
import SuppliersModule from '@/store/modules/suppliers.module'

export default createStore({
  state: {
    articleCounts: 0,
    articlePricesPending: 0,
    searchResult: null
  },
  mutations: {
    setCountInfo (state, { articleCounts, articlePricesPending }) {
      state.articleCounts = articleCounts
      state.articlePricesPending = articlePricesPending
    }
  },
  actions: {
    /**
     * Метод для получения данных об общем кол-ве
     * загруженых артикулов и кол-ве артикулов ожидающих цены
     * @returns { articleCounts: number, articlePricesPending: number }
     */
    async getCountInfo ({ commit, rootState }) {
      try {
        const { articleCounts = 0, articlePricesPending = 0 } = await Api.get('/api/supply/getCounts', {
          params: {
            user_id: rootState.ProfileModule.user.id,
            supplier_id: rootState.SuppliersModule.selectedSupplier
          }
        })
        commit('setCountInfo', { articleCounts, articlePricesPending })
      } catch (error) {
        console.error(error)
      }
    },
    async searchArticles ({ state, rootState }, query) {
      state.searchResult = await Api.get('/api/supply/search', {
        params: {
          query,
          user_id: rootState.ProfileModule.user.id
        }
      })
    },
    /**
     * Метод для загрузки файла. В ответ возвращает ссылку на загруженный файл.
     * @param commit
     * @param file
     * @returns {Promise<string>}
     */
    async uploadFile ({ commit }, file) {
      try {
        return await Api.post('/api/supply/uploadFile', {
          data: file
        })
      } catch (error) {
        console.error(error)
      }
    }
  },
  modules: {
    ArticlesModule,
    ProfileModule,
    SuppliersModule
  }
})
