import { useRoute } from 'vue-router'
import { ref, watch, computed, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { roles } from '@/constants/roles'

export const menu = () => {
  const store = useStore()
  const route = useRoute()

  const userRole = computed(() => store.state.ProfileModule.user?.role)

  const activeMenuItem = ref(null)
  const articleCounts = computed(() => store.state.articleCounts)
  const articlePricesInPending = computed(() => store.state.articlePricesPending)

  const iconColors = {
    default: '#454545',
    active: '#FFF'
  }
  const menu = ref([
    {
      items: [
        {
          allowedRoles: [roles.manager, roles.supplier],
          name: 'home',
          icon: {
            name: 'home',
            width: 15,
            height: 15,
            viewBoxSize: [15, 15],
            fill: iconColors.default
          },
          text: 'Home',
          url: '/home'
        },
        {
          allowedRoles: [roles.manager, roles.supplier],
          name: 'upload-files',
          icon: {
            name: 'upload-files',
            width: 15,
            height: 15,
            viewBoxSize: [15, 15],
            fill: iconColors.default
          },
          text: 'Upload files',
          url: '/upload-articles'
        },
        {
          allowedRoles: [roles.manager, roles.supplier],
          name: 'articles',
          icon: {
            name: 'catalogue',
            width: 15,
            height: 15,
            viewBoxSize: [15, 15],
            fill: iconColors.default
          },
          text: `All articles (${articleCounts.value})`,
          url: '/articles'
        },
        {
          allowedRoles: [roles.manager, roles.supplier],
          name: 'prices',
          icon: {
            name: 'prices',
            width: 15,
            height: 16,
            viewBoxSize: [15, 16],
            fill: iconColors.default
          },
          text: `Prices in pending (${articlePricesInPending.value})`,
          url: '/prices-in-pending'
        },
        {
          allowedRoles: [roles.manager],
          name: 'add-price-in-pending',
          icon: {
            name: 'add-document',
            width: 15,
            height: 15,
            viewBoxSize: [15, 15],
            fill: iconColors.default
          },
          text: 'Add price in pending',
          url: '/add-price-in-pending'
        },
        {
          allowedRoles: [roles.manager, roles.supplier],
          name: 'profile',
          icon: {
            name: 'profile',
            width: 15,
            height: 15,
            viewBoxSize: [15, 15],
            fill: iconColors.default
          },
          text: 'Profile',
          url: '/profile'
        }
        // {
        //   id: 4,
        //   name: 'payment-information',
        //   icon: {
        //     name: 'payment-information',
        //     width: 15,
        //     height: 16,
        //     viewBoxSize: [15, 16],
        //     fill: iconColors.default
        //   },
        //   text: 'Платежная информация',
        //   url: '/order-history'
        // }
      ]
    }
  ])

  /**
   * TODO: Временный костыль, пока не пойму как сделать свойства вложенных объектов реактивными (и надо ли вообще)
   */
  watchEffect(() => {
    const articleCountsMenuItem = menu.value[0].items.find(item => item.name === 'articles')
    const articlePricesPendingMenuItem = menu.value[0].items.find(item => item.name === 'prices')

    if (articleCountsMenuItem) {
      articleCountsMenuItem.text = `All articles (${articleCounts.value})`
    }

    if (articlePricesPendingMenuItem) {
      articlePricesPendingMenuItem.text = `Prices in pending (${articlePricesInPending.value})`
    }
  })

  watch(
    () => route.path,
    value => {
      if (activeMenuItem.value && activeMenuItem.value.icon) {
        activeMenuItem.value.icon.name = activeMenuItem.value.icon.name.split('_')[0]
        activeMenuItem.value.icon.fill = iconColors.default
      }

      const targetMenuBlock = menu.value.find(
        item => item.items.some(
          element => element.url === value || value.indexOf(element.url) !== -1
        )
      )

      if (!targetMenuBlock) return

      activeMenuItem.value = targetMenuBlock.items.find(
        item => item.url === value || value.indexOf(item.url) !== -1
      )

      if (!activeMenuItem.value.icon) return

      activeMenuItem.value.icon.name = activeMenuItem.value.icon.name + '_active'
      activeMenuItem.value.icon.fill = iconColors.active
    },
    { immediate: true }
  )

  // Вотчер смотрит на роль пользователя и на основе ее
  // фильтрует пункты меня оставляя только то что должно показываться
  // для определенной роли
  watch(
    userRole,
    (value) => {
      menu.value = menu.value.map(item => {
        item.items = item.items.filter(element => element.allowedRoles.includes(value))
        return item
      })
    },
    { immediate: true }
  )

  return {
    articlePricesInPending,
    menu
  }
}
