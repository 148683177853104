<template>
  <div class="tw-flex tw-flex-col tw-items-start tw-w-full">
    <div class="tw-flex tw-flex-col tw-items-start tw-w-full tw-gap-6 5xl:tw-gap-[30px]">
      <div class="tw-flex tw-items-center tw-w-full tw-justify-between tw-py-[6px] lg:tw-py-0">
        <h2 class="tw-text-2xl lg:tw-text-3xl 5xl:tw-text-[37.5px] tw-font-medium tw-leading-[38px] 5xl:tw-leading-normal tw-text-black">Upload your file</h2>

        <ui-button
          v-if="breakpointGreaterMd"
          class="ui-button_md ui-button_text tw-text-sm 5xl:tw-text-[17.5px] tw-font-medium tw-text-orange"
          data-cy="open-nomenclature-add-modal"
          @click="openCreateNomenclature"
        >
          <template #default>
            <ui-icon-base
              :icon="icons['plus-lg']"
              :width="15"
              :height="16"
              :view-box-size="[15, 16]"
              fill="#EA5432"
              class="5xl:tw-w-5 5xl:tw-h-5"
            />

            <span>Add nomenclature</span>
          </template>
        </ui-button>
      </div>

      <ui-button
        v-if="!breakpointGreaterMd"
        class="ui-button ui-button_w-full ui-button_md ui-button_outlined tw-border-orange"
        @click="openCreateNomenclature"
      >
        <template #default>
          <ui-icon-base
            :icon="icons['plus-lg']"
            :width="15"
            :height="16"
            :view-box-size="[15, 16]"
            fill="#EA5432"
          />

          <span class="tw-text-orange">Add nomenclature</span>
        </template>
      </ui-button>

      <sp-upload-files
        :allowed-extensions="['xls', 'xlsx', 'json', 'xml', 'csv']"
        :loading="loadingFile"
        @upload:file="onUploadFileHandler($event)"
      />

      <div class="tw-flex tw-flex-wrap tw-items-start tw-justify-between tw-w-full tw-py-3 lg:tw-pl-4 5xl:tw-pl-5 tw-gap-y-12">
        <div class="tw-flex tw-items-center tw-justify-between sm:tw-justify-normal tw-gap-4 lg:tw-gap-11 tw-py-[2.5px] tw-w-full sm:tw-w-auto">
          <div class="tw-flex tw-items-start lg:tw-items-center tw-w-auto tw-gap-3 5xl:tw-gap-[15px] tw-max-w-[170px] 5xl:tw-max-w-[212px]">
            <span class="tw-text-2xl lg:tw-text-3xl 5xl:tw-text-[37px] tw-font-medium tw-leading-[100%] lg:tw-leading-normal tw-text-orange">{{ countUploadedArticles }}</span>
            <span class="tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray tw-text-left tw-leading-4">article has been uploaded successfully</span>
          </div>

          <router-link
            to="/prices-in-pending"
            tag="div"
            class="tw-flex tw-items-start lg:tw-items-center tw-w-auto tw-gap-3 tw-max-w-[170px] 5xl:tw-max-w-[212px] tw-cursor-pointer"
          >
            <span class="tw-text-2xl lg:tw-text-3xl 5xl:tw-text-[37px] tw-font-medium tw-leading-[100%] lg:tw-leading-normal tw-text-orange">{{ pricesPending }}</span>
            <span class="tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray tw-text-left tw-leading-4 hover:tw-text-orange tw-transition tw-duration-100">article in anticipation of the price</span>
          </router-link>
        </div>

        <div class="tw-flex tw-flex-col sm:tw-flex-row tw-justify-end tw-items-center tw-gap-3 5xl:tw-gap-[15px] tw-w-full sm:tw-w-auto">
          <div class="tw-w-full sm:tw-w-[200px] md:tw-w-[300px] 5xl:tw-w-[370px]">
            <ui-input
              name="search-article-by-oem"
              placeholder="Search article by OEM"
              data-cy="search"
              :model-value="search"
              @update:model-value="search = $event"
            />
          </div>

          <sp-dropdown
            modal-id="upload-articles-templates"
            :model-value="''"
            :options="templates"
            option-label="name"
            option-value="url"
            placeholder="Template to download..."
            classes="ui-dropdown_orange tw-gap-2 5xl:tw-gap-[10px] tw-w-full sm:tw-w-auto"
            label="Choose template"
          >
            <template #option="{ option }">
              <a
                :href="option.url"
                download
              >
                {{ option.name }}
              </a>
            </template>

            <template #modal-option="{ option, close }">
              <div class="tw-flex tw-items-center tw-w-full tw-py-3 5xl:tw-py-[15px] tw-px-4 5xl:tw-px-5 tw-rounded-[5px]">
                <a
                  :href="option.url"
                  download
                  @click="close"
                >
                  {{ option.name }}
                </a>
              </div>
            </template>
          </sp-dropdown>
        </div>
      </div>

      <div class="tw-flex tw-flex-col tw-items-start tw-w-full tw-gap-2 5xl:tw-gap-[10px]">
        <div class="tw-flex tw-items-start">
          <ui-button
            class="ui-button_md ui-button_text"
            value="Delete"
            data-cy="remove-articles"
            @click="removeArticlesHandler(selectedArticles, onResetSelect)"
          />

          <ui-button
            class="ui-button_md"
            value="Cancel"
            data-cy="cancel-select"
            @click="onResetSelect"
          />
        </div>

        <div class="tw-w-full tw-overflow-x-auto">
          <ui-table
            :headers="headers"
            class="tw-min-w-[1560px] sp-table"
          >
            <template #header[remove-action]>
              <ui-checkbox
                :value="selectAll"
                :classes="['ui-checkbox_base tw-cursor-pointer']"
                data-cy="select-all"
                @click="onSelectAll(items)"
              />
            </template>

            <template #default>
              <ui-row
                :headers="headers"
                :items="filteredArticles"
                :classes="['tw-border-t tw-border-middle-gray']"
              >
                <template #item[remove-action]="{ item }">
                  <ui-checkbox
                    :value="isSelected(item.UF_OEM, item.UF_DATA)"
                    :classes="['ui-checkbox_base tw-cursor-pointer']"
                    data-cy="select-article"
                    @click="onSelectArticle(item)"
                  />
                </template>

                <template #item[UF_OEM]="{ item, index }">
                  <span v-if="!item.editable">
                    {{ item.UF_OEM }}
                  </span>

                  <ui-input
                    v-else
                    :model-value="item.UF_OEM"
                    :type="inputTypes.TEXT"
                    name="UF-OEM"
                    placeholder="OEM"
                    @input="onUpdateArticleProp({ index, prop: 'UF_OEM', value: $event.target.value })"
                  />
                </template>

                <template #item[UF_COUNT]="{ item, index }">
                  <a v-if="!item.editable">
                    {{ item.UF_COUNT }}
                  </a>

                  <ui-input
                    v-else
                    :model-value="item.UF_COUNT"
                    :type="inputTypes.TEXT"
                    name="UF-ARTICLE"
                    placeholder="Article"
                    @input="onUpdateArticleProp({ index, prop: 'UF_COUNT', value: $event.target.value })"
                  />
                </template>

                <template #item[UF_SUPPL_ID]="{ item, index }">
                  <span v-if="!item.editable">
                    {{ item.UF_SUPPL_ID }}
                  </span>

                  <ui-input
                    v-else
                    :model-value="item.UF_SUPPL_ID"
                    :type="inputTypes.TEXT"
                    name="UF-SUPPLY-ID"
                    placeholder="Supply ID"
                    @input="onUpdateArticleProp({ index, prop: 'UF_SUPPL_ID', value: $event.target.value })"
                  />
                </template>

                <template #item[UF_DATA]="{ item }">
                  <span>{{ getFormattingDate(item.UF_DATA) }}</span>
                </template>

                <template #item[UF_DESCRIPTION]="{ item, index }">
                  <span v-if="!item.editable">{{ item.UF_DESCRIPTION }}</span>

                  <ui-input
                    v-else
                    :model-value="item.UF_DESCRIPTION"
                    :type="inputTypes.TEXT"
                    name="UF-DESCRIPTION"
                    placeholder="Description"
                    @input="onUpdateArticleProp({ index, prop: 'UF_DESCRIPTION', value: $event.target.value })"
                  />
                </template>

                <template #item[UF_PRICE]="{ item, index }">
                  <span v-if="!item.editable">{{ item.UF_PRICE }}</span>

                  <ui-input
                    v-else
                    :model-value="item.UF_PRICE"
                    :type="inputTypes.NUMBER"
                    name="UF-PRICE"
                    placeholder="Price"
                    @input="onUpdateArticleProp({ index, prop: 'UF_PRICE', value: $event.target.value })"
                  />
                </template>

                <template #item[UF_AVAIBLE]="{ item, index }">
                  <span v-if="!item.editable">{{ item.UF_AVAIBLE }}</span>

                  <ui-input
                    v-else
                    :model-value="item.UF_AVAIBLE"
                    :type="inputTypes.NUMBER"
                    name="UF-AVAIBLE"
                    placeholder="Availability"
                    @input="onUpdateArticleProp({ index, prop: 'UF_AVAIBLE', value: $event.target.value })"
                  />
                </template>

                <template #item[UF_STOCK_INFO]="{ item, index }">
                  <span v-if="!item.editable">{{ item.UF_STOCK_INFO }}</span>

                  <ui-input
                    v-else
                    :model-value="item.UF_STOCK_INFO"
                    :type="inputTypes.TEXT"
                    name="UF-STOCK_INFO"
                    placeholder="Stock info"
                    @input="onUpdateArticleProp({ index, prop: 'UF_STOCK_INFO', value: $event.target.value })"
                  />
                </template>

                <template #item[UF_PRODUCTION_TIME]="{ item, index }">
                  <span v-if="!item.editable">
                    {{ item.UF_PRODUCTION_TIME }}
                  </span>

                  <ui-input
                    v-else
                    :model-value="item.UF_PRODUCTION_TIME"
                    :type="inputTypes.TEXT"
                    name="UF-PRODUCTION-TIME"
                    placeholder="Production time"
                    @input="onUpdateArticleProp({ index, prop: 'UF_PRODUCTION_TIME', value: $event.target.value })"
                  />
                </template>

                <template #item[UF_SCHEMA]="{ item, index }">
                  <a
                    v-if="!item.editable"
                    :href="item.UF_SCHEMA"
                    :class="[
                      'tw-text-sm 5xl:tw-text-[17px] tw-font-semibold tw-cursor-pointer',
                      item.UF_SCHEMA ? 'tw-text-orange' : ' tw-text-middle-gray tw-pointer-events-none'
                    ]"
                    target="_blank"
                  >
                    Schema
                  </a>

                  <ui-input
                    v-else
                    :model-value="item.UF_SCHEMA"
                    :type="inputTypes.TEXT"
                    name="UF-SCHEMA"
                    placeholder="Schema link"
                    @input="onUpdateArticleProp({ index, prop: 'UF_SCHEMA', value: $event.target.value })"
                  />
                </template>

                <template #item[UF_ANALOGS]="{ item }">
                  <tippy
                    v-if="item.UF_ANALOGS.length"
                    theme="history"
                    trigger="click"
                    hide-on-click="toggle"
                    content-class="sp-tooltip"
                    :on-click-outside="instance => instance.hide()"
                  >
                    <template #default>
                      <span class="tw-text-sm 5xl:tw-text-[17px] tw-font-semibold tw-cursor-pointer">Where is used?</span>
                    </template>

                    <template #content>
                      <div class="tw-flex tw-flex-col tw-items-start tw-gap-2">
                        <span
                          v-for="(analog, key) in item.UF_ANALOGS"
                          :key="key"
                          class="tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-dark-gray"
                        >
                          {{ analog }}
                        </span>
                      </div>
                    </template>
                  </tippy>

                  <span
                    v-else
                    class="tw-text-sm 5xl:tw-text-[17px] tw-font-semibold tw-text-middle-gray tw-pointer-events-none"
                  >
                    Where is used?
                  </span>
                </template>

                <template #item[actions]="{ item, index }">
                  <div class="tw-flex tw-items-center tw-justify-end tw-w-full tw-gap-6 5xl:tw-gap-[30px]">
                    <ui-icon-base
                      v-if="!item.editable"
                      :icon="icons['edit']"
                      :width="15"
                      :height="16"
                      :view-box-size="[15, 16]"
                      class="tw-cursor-pointer tw-min-w-[15px]"
                      @click="onChangeEditableState(index)"
                    />

                    <ui-icon-base
                      v-else
                      :icon="icons['check']"
                      :width="15"
                      :height="16"
                      :view-box-size="[15, 16]"
                      :stroke-width="1"
                      stroke="#EA5432"
                      class="tw-cursor-pointer tw-min-w-[15px]"
                      @click="onChangeEditableState(index)"
                    />

                    <ui-button
                      class="ui-button ui-button_sm ui-button_text"
                      @click="sendArticles([item])"
                    >
                      <template #default>
                        <ui-icon-base
                          :icon="icons['send']"
                          :width="15"
                          :height="15"
                          :view-box-size="[15, 15]"
                        />

                        <span>Send info</span>
                      </template>
                    </ui-button>
                  </div>
                </template>

                <template #empty>
                  <div class="tw-w-full tw-border-t tw-border-middle-gray tw-text-center tw-py-3 5xl:tw-gap-[15px]">
                    <span class="tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray">No data</span>
                  </div>
                </template>
              </ui-row>
            </template>
          </ui-table>

          <ui-loader :loading="loadingFile" />
        </div>
      </div>

      <div class="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-justify-between tw-w-full tw-gap-3 5xl:tw-gap-[15px] lg:tw-gap-0">
        <div class="tw-grid tw-grid-cols-1 xs:tw-grid-cols-2 md:tw-flex tw-flex-row tw-gap-3 lg:tw-gap-4 5xl:tw-gap-5 tw-w-full md:tw-w-auto">
          <ui-button
            value="Send all articles"
            :class="[
              'ui-button_md ui-button_primary',
              { 'ui-button_disabled' : !items.length }
            ]"
            data-cy="send-all-articles"
            @click="sendArticles(items)"
          />

          <ui-button
            value="Send selected articles"
            :class="[
              'ui-button_md ui-button_primary',
              { 'ui-button_disabled' : !selectedArticles.length }
            ]"
            data-cy="send-article"
            @click="sendArticles(selectedArticles)"
          />
        </div>

        <ui-button
          :class="[
            'ui-button_md ui-button_primary tw-w-full md:tw-w-auto',
            { 'ui-button_disabled' : !templatePricesPending }
          ]"
        >
          <template #default>
            <a
              :href="templatePricesPending"
              download
            >
              Download list of articles with pending prices
            </a>
          </template>
        </ui-button>
      </div>
    </div>
  </div>
</template>

<script>
// Components
import SpCreateNomenclatureModal from '@/modals/sp-create-nomenclature-modal/sp-create-nomenclature-modal.vue'
import SpDropdown from '@/modules/sp-dropdown/sp-dropdown.vue'
import SpUploadFiles from '@/modules/sp-upload-files/sp-upload-files.vue'

// Utils
import { getFormattingDate } from '@/utils/getFormattingDate'
import { selectArticlesMixin } from '@/mixins/select-articles.mixin'
import { inputTypes } from '@/constants/input-types'
import { storages } from '@/constants/storages'
import { templateTypes } from '@/constants/template-types'
import icons from '@/utils/icons'

// Plugins and libraries
import { Tippy } from 'vue-tippy'
import { useToast } from 'primevue/usetoast'
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { useBreakpoints, breakpointsTailwind } from '@vueuse/core'
import { useModal } from 'vue-final-modal'
import 'tippy.js/dist/tippy.css'

export default {
  name: 'UploadArticles',
  components: {
    SpDropdown,
    SpUploadFiles,
    Tippy
  },
  setup () {
    const store = useStore()
    const toast = useToast()

    // Breakpoints
    const breakpoints = useBreakpoints({
      ...breakpointsTailwind,
      '2xl': '1440px',
      '3xl': '1696px',
      '4xl': '1920px'
    })
    const breakpointGreaterMd = breakpoints.greater('md')

    const loadingFile = ref(false)
    const { open: openCreateNomenclature } = useModal({ component: SpCreateNomenclatureModal })

    // Шаблоны для скачивания
    const templates = computed(() => store.state.ArticlesModule.templates)

    // Колонкки и даннеые для таблицы
    const headers = [
      {
        column: 'remove-action',
        label: '',
        size: 'tw-w-5 lg:tw-w-[3.5%] tw-min-w-5 lg:tw-min-w-[3.5%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-pl-0 lg:tw-pl-6 tw-pr-0 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray tw-text-sm tw-py-3 tw-px-6 tw-pl-0 lg:tw-pl-6 tw-pr-0 tw-min-h-[72px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_OEM',
        label: 'OEM number',
        size: 'tw-w-[9%] tw-min-w-[9%] lg:tw-w-[9%] lg:tw-min-w-[9%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-pl-3 lg:tw-pl-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray tw-text-sm tw-py-3 tw-px-6 tw-pl-3 lg:tw-pl-6 tw-min-h-[72px] tw-flex tw-items-center data-cy-oem',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_SUPPL_ID',
        label: 'Suppl ID',
        size: 'tw-w-[9%] tw-min-w-[9%] lg:tw-w-[9%] lg:tw-min-w-[9%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray tw-text-sm tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_PRICE',
        label: 'Price (CNY)',
        size: 'tw-w-[8%] tw-min-w-[8%] lg:tw-w-[6%] lg:tw-min-w-[6%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray tw-text-sm tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_COUNT',
        label: 'Count',
        size: 'tw-w-[6%] tw-min-w-[6%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray tw-text-sm tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_DATA',
        label: 'Date',
        size: 'tw-w-[7%] tw-min-w-[7%] lg:tw-w-[6%] lg:tw-min-w-[6%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray tw-text-sm tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_DESCRIPTION',
        label: 'Description',
        size: 'tw-w-[8%] tw-min-w-[8%] lg:tw-w-[9%] lg:tw-min-w-[9%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray tw-text-sm tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_AVAIBLE',
        label: 'Availability (stock / transit)',
        size: 'tw-w-[8%] tw-min-w-[8%] lg:tw-w-[9%] lg:tw-min-w-[9%]',
        classesHeader: 'tw-py-[6px] tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray tw-text-sm tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_STOCK_INFO',
        label: 'Stock info',
        size: 'tw-w-[8%] tw-min-w-[8%] lg:tw-w-[7%] lg:tw-min-w-[7%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray tw-text-sm tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_PRODUCTION_TIME',
        label: 'Production time',
        size: 'tw-w-[8%] tw-min-w-[8%] lg:tw-w-[6%] lg:tw-min-w-[6%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray tw-text-sm tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_ANALOGS',
        label: 'Where is used?',
        size: 'tw-w-[9%] tw-min-w-[9%] lg:tw-w-[9%] lg:tw-min-w-[9%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray tw-text-sm tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center tw-whitespace-nowrap',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_SCHEMA',
        label: 'Schema',
        size: 'tw-w-[6%] tw-min-w-[6%] lg:tw-w-[6%] lg:tw-min-w-[6%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray tw-text-sm tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'actions',
        label: '',
        size: 'tw-w-[13%] tw-min-w-[13%] lg:tw-w-[14%] lg:tw-min-w-[14%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center tw-ml-auto',
        classesItem: 'tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray tw-text-sm tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center tw-ml-auto',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      }
    ]
    const items = computed(() => store.state.ArticlesModule.articlesUploaded)

    // Логика фильтрации по поисковому запросу
    const search = ref('')
    const filteredArticles = computed(() => {
      if (!items.value.length) {
        return []
      }

      return items.value.filter(item => item.UF_OEM.includes(search.value) || !search.value.length)
    })

    // Получения из стейта данных об артикулах (кол-во артикулов для разных страниц), а так же массива шаблонов
    const countUploadedArticles = computed(() => store.state.ArticlesModule.countUploadedArticles)
    const pricesPending = computed(() => store.state.articlePricesPending)
    const templatePricesPending = computed(() => store.state.ArticlesModule.templatePendingPricesLink)

    onMounted(async () => {
      loadingFile.value = true

      // Получение массива шаблонов
      const getTemplates = await store.dispatch('ArticlesModule/getTemplates', templateTypes.uploadPage)
      store.commit('ArticlesModule/setTemplates', getTemplates)

      // Проверяем есть ли в localStorage ранее загруженные артикулы на странице
      const cachedArticles = JSON.parse(localStorage.getItem(storages.uploadPageArticles))
      const cachedArticlesCount = JSON.parse(localStorage.getItem(storages.uploadPageArticlesCount))

      // Если в localStorage нашли ранее загруженные артикулы, то записываем и показываем их
      if (cachedArticles !== null && cachedArticlesCount !== null) {
        store.commit('ArticlesModule/setArticles', { articles: cachedArticles, targetArray: 'articlesUploaded' })
        store.commit('ArticlesModule/setCountUploadedArticles', cachedArticlesCount)
      }

      loadingFile.value = false
    })

    /**
     * @param articles
     * @param cb Массив колбэков
     * @returns {Promise<void>}
     */
    function removeArticlesHandler (articles, cb) {
      store.commit('ArticlesModule/removeArticles', { articles, targetArray: 'articlesUploaded' })
      store.commit('ArticlesModule/setCountUploadedArticles', items.value.length)

      localStorage.setItem(storages.uploadPageArticles, JSON.stringify(items.value))
      localStorage.setItem(storages.uploadPageArticlesCount, JSON.stringify(countUploadedArticles.value))

      cb()
    }

    function onCancelUploads (cb) {
      store.commit('ArticlesModule/setArticles', { articles: [], targetArray: 'articlesUploaded' })

      localStorage.setItem(storages.uploadPageArticles, null)
      localStorage.setItem(storages.uploadPageArticlesCount, JSON.stringify(0))

      cb()

      toast.add({
        detail: 'Articles have been removed.',
        life: 3000
      })
    }

    // Метод обработки загрузки файла
    async function onUploadFileHandler (file) {
      loadingFile.value = true

      if (!file) {
        return alert('Загрузите файл')
      }

      const formData = new FormData()
      formData.append('file', file)

      const { result, countUploadedArticles } = await store.dispatch('ArticlesModule/uploadArticlesFromFile', formData)
      const mappedArticles = result.map(item => ({
        ...item,
        editable: false
      }))

      store.commit('ArticlesModule/mergeArticles', { articles: mappedArticles, targetArray: 'articlesUploaded' })
      store.commit('ArticlesModule/setCountUploadedArticles', countUploadedArticles)

      localStorage.setItem(storages.uploadPageArticles, JSON.stringify(store.state.ArticlesModule.articlesUploaded))
      localStorage.setItem(storages.uploadPageArticlesCount, JSON.stringify(store.state.ArticlesModule.countUploadedArticles))

      loadingFile.value = false
      toast.add({
        detail: 'Articles have been uploaded.',
        life: 3000
      })
    }

    async function sendArticles (articles) {
      await store.dispatch('ArticlesModule/sendArticles', articles)

      store.commit('ArticlesModule/removeArticles', { articles, targetArray: 'articlesUploaded' })
      store.commit('ArticlesModule/setCountUploadedArticles', items.value.length)

      localStorage.setItem(storages.uploadPageArticles, JSON.stringify(items.value))
      localStorage.setItem(storages.uploadPageArticlesCount, JSON.stringify(countUploadedArticles.value))

      toast.add({
        detail: 'Articles have been sent.',
        life: 3000
      })

      await store.dispatch('getCountInfo')
    }

    return {
      breakpointGreaterMd,
      countUploadedArticles,
      filteredArticles,
      getFormattingDate,
      headers,
      icons,
      inputTypes,
      items,
      loadingFile,
      onCancelUploads,
      onChangeEditableState: (index) => store.commit('ArticlesModule/changeEditableState', { index, targetArray: 'articlesUploaded' }),
      openCreateNomenclature,
      onUpdateArticleProp: ({ index, prop, value }) => store.commit('ArticlesModule/updateArticleProperty', { targetArray: 'articlesUploaded', index, prop, value }),
      onUploadFileHandler,
      pricesPending,
      removeArticlesHandler,
      search,
      sendArticles,
      templates,
      templatePricesPending,
      ...selectArticlesMixin()
    }
  }
}
</script>

<style lang="scss">
// TODO: Разобраться откуда появляется текст
// с количеством выбранных элеменетов и после удалить эти стили
.p-hidden-accessible { display: none !important; }
</style>
