import Api from '@/api'

export default {
  namespaced: true,
  state: {
    articles: [],
    articlesAddedPricePending: [],
    articlesPricePending: [],
    articlesUploaded: [],
    countPricesPending: 0,
    countUploadedArticles: 0,
    templates: [],
    templatePendingPricesLink: null
  },
  mutations: {
    appendUploadedArticles (state, article) {
      state.articlesUploaded.push(article)
      ++state.countUploadedArticles
    },
    changeEditableState (state, { index, targetArray }) {
      const article = state[targetArray][index]
      article.editable = !article.editable
    },
    mergeArticles (state, { articles, targetArray }) {
      articles.forEach(item => {
        const isThereDuplicateIndex = state[targetArray].findIndex(element => element.UF_OEM === item.UF_OEM && element.UF_SUPPL_ID === item.UF_SUPPL_ID)

        if (isThereDuplicateIndex !== -1) {
          state[targetArray].splice(isThereDuplicateIndex, 1)
          return
        }

        state[targetArray].push(item)
      })
    },
    removeArticles (state, { articles, targetArray, isUploadPage = false }) {
      if (!articles) return

      const copyOfArticles = JSON.parse(JSON.stringify(articles))

      copyOfArticles.forEach(item => {
        const index = state[targetArray].findIndex(element => element.UF_OEM === item.UF_OEM && parseInt(element.UF_DATA) === parseInt(item.UF_DATA))
        state[targetArray].splice(index, 1)
      })

      if (isUploadPage) {
        state.countUploadedArticles -= copyOfArticles.length
      }
    },
    rewriteArticles (state, { articles, targetArray }) {
      articles.forEach(item => {
        const equalArticleIndex = state[targetArray].findIndex(element => element.UF_OEM === item.UF_OEM)

        if (equalArticleIndex !== -1) {
          state[targetArray][equalArticleIndex] = {
            ...state[targetArray][equalArticleIndex],
            UF_PRICE: item.UF_PRICE,
            UF_AVAIBLE: item.UF_AVAIBLE,
            UF_PRODUCTION_TIME: item.UF_PRODUCTION_TIME,
            UF_STOCK_INFO: item.UF_STOCK_INFO
          }
        }
      })
    },
    setArticles (state, { articles, targetArray }) {
      state[targetArray] = articles
    },
    setCountUploadedArticles (state, value) {
      state.countUploadedArticles = value
    },
    setTemplates (state, templates) {
      state.templates = templates
    },
    setTemplatePendingPricesLink (state, link) {
      state.templatePendingPricesLink = link
    },
    updateArticleProperty (state, { targetArray, index, prop, value }) {
      state[targetArray][index][prop] = value
    }
  },
  actions: {
    /**
     * Запрос для отправки поставщику артикулов которые ожидаюти цены. Только для менеджеров
     * @param state
     * @param rootState
     * @param articles - Массив артикулов
     * @returns { Promise<Object> }
     */
    async addPriceInPending ({ state, rootState }, articles) {
      try {
        await Api.post('/api/suppliers/addPriceInPending', {
          data: {
            articles: articles,
            supplier_id: rootState.SuppliersModule.selectedSupplier?.id
          }
        })
      } catch (error) {
        console.error(error)
      }
    },
    /**
     * Метод для получения всех артикулов
     * @returns { articles: articles[] }
     */
    async getArticles ({ rootState }) {
      try {
        return await Api.get('/api/supply/getArticles', {
          params: {
            user_id: rootState.ProfileModule.user.id,
            supplier_id: rootState.SuppliersModule.selectedSupplier?.id
          }
        })
      } catch (error) {
        console.error(error)
        return []
      }
    },
    /**
     * Метод для получения артикулов, которые ожидают цены
     * @returns { allCount: number, fileLink: string, result: articles[] }
     */
    async getPricesPending ({ rootState }) {
      try {
        return await Api.get('/api/supply/WaitPrices', {
          params: {
            user_id: rootState.ProfileModule.user.id,
            supplier_id: rootState.SuppliersModule.selectedSupplier?.id
          }
        })
      } catch (error) {
        console.error(error)
        return null
      }
    },
    /**
     * Метод для получения массива шаблонов.
     * @returns { result: array } Возвращает массив шаблонов { name: string, url: string }
     */
    async getTemplates ({ commit }, page) {
      try {
        return await Api.get('/api/supply/getTemplates', {
          params: {
            page
          }
        }).then(res => res?.result || [])
      } catch (error) {
        console.error(error)
      }
    },
    /**
     * Метод для удаления артикулов. В качестве аргумента article принимает массив артикулов,
     * которые надо удалить
     * @param state
     * @param articles
     * @param isPendingPage
     * @returns {Promise<void>}
     */
    async removeArticle ({ rootState }, { articles, isPendingPage = false }) {
      try {
        await Api.post('/api/supply/deleteOem', {
          data: {
            articles,
            isPendingPage,
            user_id: rootState.ProfileModule.user.id
          }
        })
      } catch (error) {
        console.error(error)
      }
    },
    /**
     * Метод для отправки артикулов. Если в качестве аргумента не был
     * передан массив артикулов, то отправляет то что хранится в стэйте.
     * @param state
     * @param rootState
     * @param articles
     * @returns {Promise<void>}
     */
    async sendArticles ({ state, rootState }, articles) {
      try {
        await Api.post('/api/supply/SendInformation', {
          data: {
            articles,
            user_id: rootState.ProfileModule.user.id,
            supplier_id: rootState.SuppliersModule.selectedSupplier?.id
          }
        })
      } catch (error) {
        console.error(error)
      }
    },
    /**
     * Метод для загрузки файла с артикулами. В ответ возвращает массив артикулов из файла.
     * @param commit
     * @param file
     * @returns {Promise<articles[]>}
     */
    async uploadArticlesFromFile ({ commit }, file) {
      try {
        return await Api.post('/api/supply/getDataFromFile', {
          data: file
        })
      } catch (error) {
        console.error(error)
        return []
      }
    }
  }
}
