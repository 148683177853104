import Api from '@/api'

export default {
  namespaced: true,
  state: {
    selectedSupplier: null,
    suppliers: []
  },
  mutations: {
    setSelectedSupplier (state, supplier) {
      state.selectedSupplier = supplier
    },
    setSuppliers (state, suppliers) {
      state.suppliers = suppliers
    }
  },
  actions: {
    async getSuppliers ({ rootState }) {
      return await Api.get('/api/supplies', {
        params: {
          user_id: rootState.ProfileModule.user?.id
        }
      })
    },
    async sendNotification ({ state }) {
      await Api.get('/api/suppliers/send-notification', {
        params: {
          supplier_id: state.selectedSupplier?.id
        }
      })
    }
  }
}
