<template>
  <div class="tw-flex tw-flex-col tw-items-start tw-w-full tw-gap-6 5xl:tw-gap-[30px]">
    <sp-upload-files
      :allowed-extensions="['xls', 'xlsx', 'json', 'xml', 'csv']"
      :loading="loading"
      @upload:file="onUploadFileHandler($event)"
    />

    <div class="tw-flex tw-flex-col tw-items-start tw-w-full tw-gap-2 5xl:tw-gap-[10px]">
      <div class="tw-flex tw-flex-col md:tw-flex-row tw-items-start lg:tw-items-center tw-justify-between tw-w-full tw-gap-6 lg:tw-gap-0">
        <div class="tw-flex tw-items-start tw-order-2 md:tw-order-none">
          <ui-button
            class="ui-button_md ui-button_text"
            value="Delete"
            @click="onDeleteArticle(selectedArticles, onResetSelect)"
          />

          <ui-button
            class="ui-button_md"
            value="Cancel"
            @click="onResetSelect"
          />
        </div>

        <div class="tw-w-full md:tw-w-[300px] 5xl:tw-w-[375px]">
          <ui-input
            name="search-article-by-oem"
            placeholder="Search article by OEM"
            :type="inputTypes.TEXT"
            :model-value="search"
            @update:model-value="search = $event"
          />
        </div>
      </div>

      <div class="tw-flex tw-w-full tw-overflow-x-auto tw-relative">
        <ui-table
          :headers="headers"
          class="sp-table tw-min-w-[1568px]"
        >
          <template #header[remove-action]>
            <ui-checkbox
              :value="selectAll"
              :classes="['ui-checkbox_base tw-cursor-pointer']"
              @click="onSelectAll(items)"
            />
          </template>

          <template #default>
            <ui-row
              :headers="headers"
              :items="filteredArticles"
              :classes="['tw-border-t tw-border-middle-gray']"
            >
              <template #item[remove-action]="{ item }">
                <ui-checkbox
                  :value="isSelected(item.UF_OEM, item.UF_DATA)"
                  :classes="['ui-checkbox_base tw-cursor-pointer']"
                  @click="onSelectArticle(item)"
                />
              </template>

              <template #item[UF_DATA]="{ item }">
                <span>{{ getFormattingDate(item.UF_DATA) }}</span>
              </template>

              <template #item[UF_PRICE]="{ item, index }">
                <span v-if="!item.editable">{{ item.UF_PRICE }}</span>

                <ui-input
                  v-else
                  :model-value="item.UF_PRICE"
                  :type="inputTypes.NUMBER"
                  name="UF-PRICE"
                  placeholder="Price"
                  @input="onUpdateArticleProp({ index, prop: 'UF_PRICE', value: $event.target.value })"
                />
              </template>

              <template #item[UF_SCHEMA]="{ item }">
                <a
                  :href="item.UF_SCHEMA"
                  :class="[
                    'tw-text-sm 5xl:tw-text-[17px] tw-font-medium tw-cursor-pointer',
                    item.UF_SCHEMA ? 'tw-text-orange' : 'tw-text-middle-gray tw-pointer-events-none'
                  ]"
                  target="_blank"
                >
                  Schema
                </a>
              </template>

              <template #item[UF_AVAIBLE]="{ item, index }">
                <span v-if="!item.editable">{{ item.UF_AVAIBLE }}</span>

                <ui-input
                  v-else
                  :model-value="item.UF_AVAIBLE"
                  :type="inputTypes.NUMBER"
                  name="UF-AVAIBLE"
                  placeholder="Availability"
                  @input="onUpdateArticleProp({ index, prop: 'UF_AVAIBLE', value: $event.target.value })"
                />
              </template>

              <template #item[UF_STOCK_INFO]="{ item, index }">
                <span v-if="!item.editable">
                  {{ item.UF_STOCK_INFO }}
                </span>

                <ui-input
                  v-else
                  :model-value="item.UF_STOCK_INFO"
                  :type="inputTypes.TEXT"
                  name="UF-STOCK-INFO"
                  placeholder="Stock info"
                  @input="onUpdateArticleProp({ index, prop: 'UF_STOCK_INFO', value: $event.target.value })"
                />
              </template>

              <template #item[UF_PRODUCTION_TIME]="{ item, index }">
                <span v-if="!item.editable">
                  {{ item.UF_PRODUCTION_TIME }}
                </span>

                <ui-input
                  v-else
                  :model-value="item.UF_PRODUCTION_TIME"
                  :type="inputTypes.TEXT"
                  name="UF-PRODUCTION-TIME"
                  placeholder="Production time"
                  @input="onUpdateArticleProp({ index, prop: 'UF_PRODUCTION_TIME', value: $event.target.value })"
                />
              </template>

              <template #item[UF_ANALOGS]="{ item }">
                <tippy
                  v-if="item.UF_ANALOGS.length"
                  theme="history"
                  trigger="click"
                  hide-on-click="toggle"
                  content-class="sp-tooltip"
                  :on-click-outside="instance => instance.hide()"
                >
                  <template #default>
                    <span class="tw-text-sm 5xl:tw-text-[17px] tw-font-semibold tw-cursor-pointer">Where is used?</span>
                  </template>

                  <template #content>
                    <div class="tw-flex tw-flex-col tw-items-start tw-gap-2">
                      <span
                        v-for="(analog, key) in item.UF_ANALOGS"
                        :key="key"
                        class="tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-dark-gray"
                      >
                        {{ analog }}
                      </span>
                    </div>
                  </template>
                </tippy>

                <span
                  v-else
                  class="tw-text-sm 5xl:tw-text-[17px] tw-font-semibold tw-text-middle-gray tw-pointer-events-none"
                >
                  Where is used?
                </span>
              </template>

              <template #item[actions]="{ item }">
                <div class="tw-flex tw-items-center tw-justify-end tw-w-full tw-gap-4 5xl:tw-gap-5">
                  <ui-button
                    :class="[
                      'ui-button ui-button_sm ui-button_text',
                      { 'ui-button_disabled' : !(Boolean(item.UF_PRICE) || Boolean(item.UF_AVAIBLE) || Boolean(item.UF_PRODUCTION_TIME)) }
                    ]"
                    @click="sendArticles([item])"
                  >
                    <template #default>
                      <ui-icon-base
                        :icon="icons['send']"
                        :width="15"
                        :height="15"
                        :view-box-size="[15, 15]"
                        class="5xl:tw-w-[18px] 5xl:tw-h-[18px]"
                      />

                      <span>Send info</span>
                    </template>
                  </ui-button>
                </div>
              </template>

              <template #empty>
                <div class="tw-w-full tw-border-t tw-border-middle-gray tw-text-center tw-py-3">
                  <span class="tw-text-sm 5xl:tw-text-[17px] tw-font-regular tw-text-dark-gray">No data</span>
                </div>
              </template>
            </ui-row>
          </template>
        </ui-table>

        <ui-loader :loading="loading" />
      </div>
    </div>

    <div class="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-justify-between tw-w-full tw-gap-3 md:tw-gap-0">
      <div class="tw-flex tw-flex-col md:tw-flex-row tw-w-full md:tw-w-auto tw-gap-3 md:tw-gap-4 5xl:tw-gap-5">
        <ui-button
          value="Send all articles"
          :class="[
            'ui-button_md ui-button_primary tw-w-full md:tw-w-auto',
            { 'ui-button_selected' : !items.length }
          ]"
          @click="sendArticles(items)"
        />

        <ui-button
          value="Send selected articles"
          :class="[
            'ui-button_md ui-button_primary tw-w-full md:tw-w-auto',
            { 'ui-button_disabled' : !selectedArticles.length }
          ]"
          @click="sendArticles(selectedArticles)"
        />
      </div>

      <ui-button
        :class="[
          'ui-button_md ui-button_primary tw-w-full md:tw-w-auto',
          { 'ui-button_disabled' : !templatePricesPending }
        ]"
      >
        <template #default>
          <a
            :href="templatePricesPending"
            download
          >
            Download list of articles with pending prices
          </a>
        </template>
      </ui-button>
    </div>
  </div>
</template>

<script>
import SpUploadFiles from '@/modules/sp-upload-files/sp-upload-files.vue'

import 'tippy.js/dist/tippy.css'
import icons from '@/utils/icons'
import { inputTypes } from '@/constants/input-types'
import { selectArticlesMixin } from '@/mixins/select-articles.mixin'
import { Tippy } from 'vue-tippy'
import { computed, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useToast } from 'primevue/usetoast'
import { getFormattingDate } from '@/utils/getFormattingDate'

export default {
  name: 'prices-in-pending',
  components: {
    SpUploadFiles,
    Tippy
  },
  setup () {
    const toast = useToast()
    const store = useStore()

    const loading = ref(false)
    /**
     * Table data
     */
    const headers = [
      {
        column: 'remove-action',
        label: '',
        size: 'tw-w-5 lg:tw-w-[3.5%] tw-min-w-5 lg:tw-min-w-[3.5%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-pl-0 lg:tw-pl-6 tw-pr-0 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm tw-font-regular tw-text-dark-gray tw-text-sm 5xl:tw-text-[17px] tw-py-3 tw-px-6 tw-pl-0 lg:tw-pl-6 tw-pr-0 tw-min-h-[72px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_OEM',
        label: 'OEM number',
        size: 'tw-w-[9%] tw-min-w-[9%] lg:tw-w-[9%] lg:tw-min-w-[9%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-pl-3 lg:tw-pl-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm tw-font-regular tw-text-dark-gray tw-text-sm 5xl:tw-text-[17px] tw-py-3 tw-px-6 tw-pl-3 lg:tw-pl-6 tw-min-h-[72px] tw-flex tw-items-center data-cy-oem',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_SUPPL_ID',
        label: 'Suppl ID',
        size: 'tw-w-[9%] tw-min-w-[9%] lg:tw-w-[9%] lg:tw-min-w-[9%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm tw-font-regular tw-text-dark-gray tw-text-sm 5xl:tw-text-[17px] tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_PRICE',
        label: 'Price (CNY)',
        size: 'tw-w-[8%] tw-min-w-[8%] lg:tw-w-[8%] lg:tw-min-w-[8%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm tw-font-regular tw-text-dark-gray tw-text-sm 5xl:tw-text-[17px] tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_COUNT',
        label: 'Count',
        size: 'tw-w-[6%] tw-min-w-[6%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm tw-font-regular tw-text-dark-gray tw-text-sm 5xl:tw-text-[17px] tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_DATA',
        label: 'Date',
        size: 'tw-w-[7%] tw-min-w-[7%] lg:tw-w-[7%] lg:tw-min-w-[7%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm tw-font-regular tw-text-dark-gray tw-text-sm 5xl:tw-text-[17px] tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_DESCRIPTION',
        label: 'Description',
        size: 'tw-w-[11%] tw-min-w-[11%] lg:tw-w-[9%] lg:tw-min-w-[9%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm tw-font-regular tw-text-dark-gray tw-text-sm 5xl:tw-text-[17px] tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_AVAIBLE',
        label: 'Availability (stock / transit)',
        size: 'tw-w-[8%] tw-min-w-[8%] lg:tw-w-[9%] lg:tw-min-w-[9%]',
        classesHeader: 'tw-py-[6px] tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm tw-font-regular tw-text-dark-gray tw-text-sm 5xl:tw-text-[17px] tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_STOCK_INFO',
        label: 'Stock info',
        size: 'tw-w-[8%] tw-min-w-[8%] lg:tw-w-[7%] lg:tw-min-w-[7%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm tw-font-regular tw-text-dark-gray tw-text-sm 5xl:tw-text-[17px] tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_PRODUCTION_TIME',
        label: 'Production time',
        size: 'tw-w-[8%] tw-min-w-[8%] lg:tw-w-[6%] lg:tw-min-w-[6%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm tw-font-regular tw-text-dark-gray tw-text-sm 5xl:tw-text-[17px] tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_ANALOGS',
        label: 'Where is used?',
        size: 'tw-w-[9%] tw-min-w-[9%] lg:tw-w-[9%] lg:tw-min-w-[9%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesItem: 'tw-text-sm tw-font-regular tw-text-dark-gray tw-text-sm 5xl:tw-text-[17px] tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center tw-whitespace-nowrap',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'UF_SCHEMA',
        label: 'Schema',
        size: 'tw-w-[6%] tw-min-w-[6%] lg:tw-w-[6%] lg:tw-min-w-[6%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center',
        classesIcon: 'mr-2',
        classesItem: 'tw-text-sm tw-font-regular tw-text-dark-gray tw-text-sm 5xl:tw-text-[17px] tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center',
        icon: null,
        onClick: () => {}
      },
      {
        column: 'actions',
        label: '',
        size: 'tw-w-[10.5%] tw-min-w-[10.5%] lg:tw-w-[11%] lg:tw-min-w-[11%]',
        classesHeader: 'tw-py-3 tw-px-6 tw-self-center tw-text-sm lg:tw-text-xs 5xl:tw-text-base tw-font-medium tw-text-gray tw-min-h-[42px] tw-flex tw-items-center tw-ml-auto',
        classesItem: 'tw-text-sm tw-font-regular tw-text-dark-gray tw-text-sm 5xl:tw-text-[17px] tw-py-3 tw-px-6 tw-min-h-[72px] tw-flex tw-items-center tw-ml-auto',
        classesIcon: 'mr-2',
        icon: null,
        onClick: () => {}
      }
    ]
    const items = computed(() => store.state.ArticlesModule.articlesPricePending)
    /**
     * Template with prices in pending
     */
    const templatePricesPending = computed(() => store.state.ArticlesModule.templatePendingPricesLink)

    /**
     * Search
     */
    const search = ref('')
    const filteredArticles = computed(() => {
      if (!items.value.length) {
        return []
      }

      return items.value.filter(item => item.UF_OEM.includes(search.value) || !search.value.length)
    })

    /**
     * Get prices pending
     */
    onMounted(async () => {
      loading.value = true
      await getArticles()
      loading.value = false
    })

    async function getArticles () {
      const { result = [], fileLink = null } = await store.dispatch('ArticlesModule/getPricesPending')
      const mappedArticles = result.map(item => ({ ...item, editable: true }))

      store.commit('ArticlesModule/setArticles', { articles: mappedArticles, targetArray: 'articlesPricePending' })
      store.commit('ArticlesModule/setTemplatePendingPricesLink', fileLink)
    }

    async function onDeleteArticle (articles, cb) {
      await store.dispatch('ArticlesModule/removeArticle', {
        articles: articles.map(item => item.UF_OEM),
        isPendingPage: true
      })
      await getArticles()
      await store.dispatch('getCountInfo')

      cb()
    }

    async function onUploadFileHandler (file) {
      loading.value = true

      if (!file) {
        return alert('Загрузите файл')
      }

      const formData = new FormData()

      formData.append('file', file)
      formData.append('isUpdate', true)

      const { result, countUploadedArticles } = await store.dispatch('ArticlesModule/uploadArticlesFromFile', formData)
      const mappedArticles = result.map(item => ({
        ...item,
        editable: true
      }))

      store.commit('ArticlesModule/rewriteArticles', { articles: mappedArticles, targetArray: 'articlesPricePending' })
      store.commit('setCountInfo', {
        articleCounts: store.state.articleCounts,
        articlePricesPending: countUploadedArticles
      })

      loading.value = false
    }

    async function sendArticles (articles) {
      // Получаем все артикулы у которых заполнено одно из трех полей
      const filteredArticles = articles.filter(item => Boolean(item.UF_PRICE) || Boolean(item.UF_AVAIBLE) || Boolean(item.UF_PRODUCTION_TIME))

      await store.dispatch('ArticlesModule/sendArticles', filteredArticles)
      store.commit('ArticlesModule/removeArticles', { articles: filteredArticles, targetArray: 'articlesPricePending' })

      toast.add({
        detail: 'Article have been sent.',
        life: 3000
      })

      /**
       * Получаемы свежые данные по количеству артикулов и артикулов ожидающих цену
       */
      await store.dispatch('getCountInfo')
    }

    return {
      filteredArticles,
      headers,
      icons,
      inputTypes,
      items,
      getFormattingDate,
      loading,
      onChangeEditableState: (index) => store.commit('ArticlesModule/changeEditableState', index),
      onDeleteArticle,
      onUploadFileHandler,
      onUpdateArticleProp: ({ index, prop, value }) => store.commit('ArticlesModule/updateArticleProperty', { targetArray: 'articlesPricePending', index, prop, value }),
      search,
      sendArticles,
      templatePricesPending,
      ...selectArticlesMixin()
    }
  }
}
</script>
