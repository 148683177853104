import { createApp } from 'vue'
import router from './router'
import store from './store'

// Components
import App from './app.vue'

//  Libraries
import PrimeVue from 'primevue/config'
import PrimeTooltip from 'primevue/tooltip'
import ToastService from 'primevue/toastservice'
import EpartsUi from '@mikael.tadesse/eparts-ui/dist/library'
import { createVfm } from 'vue-final-modal'

import 'primevue/resources/themes/lara-light-indigo/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'vue-final-modal/style.css'
import '@mikael.tadesse/eparts-ui/dist/library.mjs.css'
import '@/styles/main.scss'

const app = createApp(App)
const vfm = createVfm()

app.use(store)
app.use(router)
app.use(EpartsUi)
app.use(PrimeVue)
app.use(ToastService)
app.use(vfm)

app.directive('tooltip', PrimeTooltip)

app.mount('#app')
